import cryptojs from 'crypto-js';
import APIConstants from "../../shared/modules/APIConstants";
const Constants = new APIConstants();

class helper {
  uniqid = (prefix = "", random = false) => {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${
      random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
    }`;
  };

  encryptData = (data) => {
    const secret = Constants.SECRET_KEY;
    const encryptedData = cryptojs.AES.encrypt(data.toString(), secret);
    return encryptedData.toString(cryptojs.format.Base64);
    // return encryptedData;
  }

  decryptData = (data) => {
    const secret = Constants.SECRET_KEY;
   try{
    const bytes = cryptojs.AES.decrypt(data, secret);
    const decryptedData = bytes.toString(cryptojs.enc.Utf8);

    return decryptedData;
   } catch (error) {
    console.log(error);
    return null;
   }
  }
}

const Helper = new helper();
export default Helper;
