import React, { Component } from "react";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
// import 
// { 
  // StyleSheet,
  // View
// } from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";
import OtpCodeModal from "../../ComponentsFC/ui/modals/OtpCodeModal";
// import { Button } from "antd";
// import ServiceAuthApi from '../../api_services/auth'
// import { Failed } from "../_components/Modal";

class FirstForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowOtpModal: false,
      verifyType: "",
      // Initialize state properties here
    };
  }

  render() {
    const _this = this.props._this;
    const {
      isAffiliated,
      hospitals,
      // createUserHospital,
    } = _this.state;
    let gender = "male";
    if (_this.state.gender) {
      gender = _this.state.gender;
    }

    const {
      // height,
      width,
      // isMobile,
      dob_date,
      options_languages,
      options_medicalAssociations,
    } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    let age = ""; //Age is 24 y/o

    if (dob_date) {
      age = "Age is " + UI.get_age(dob_date) + " y/o";
    }

    const Languages = options_languages?.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });

    const MedicalAssociations = options_medicalAssociations?.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });

    // const verifyEmailOrPhoneNumber = async (type) => {
    //   this.setState({ verifyType: type })
    //   _this.show_loading();
    //   if (type === "email") {
    //     const payload = {
    //       email: _this.state.email,
    //       role: "provider"
    //     };
    //     ServiceAuthApi.verifyEmail(payload).then(res => {
    //       if (res.status === 200) {
    //         this.setState({ isShowOtpModal: true });
    //         _this.hide_loading();
    //       } else {
    //         _this.hide_loading();
    //         if (res.message.includes("wrong") || res.message.includes("expired")) {
    //           _this.show_modal(
    //             <Failed
    //               title={"Failed"}
    //               description={res.message}
    //               onDismiss={() => {
    //                 _this.hide_modal();
    //                 UI.logout();
    //               }}
    //             />
    //           );
    //         } else {
    //           _this.show_modal(
    //             <Failed
    //               title={"Failed"}
    //               description={res.message}
    //               onDismiss={() => {
    //                 _this.hide_modal();
    //                 UI.logout();
    //               }}
    //             />
    //           );
    //         }
    //       }
    //     });
    //   } else if (type === "phoneNumber") {
    //     const payload = {
    //       phoneNumberPrefix: "+63",
    //       phoneNumber: _this.state.contact_no,
    //       role: "provider"
    //     };
    //     ServiceAuthApi.verifyMobileNumber(payload).then(res => {
    //       if (res.status === 200) {
    //         this.setState({ isShowOtpModal: true });
    //         _this.hide_loading();
    //       } else {
    //         _this.hide_loading();
    //         _this.show_modal(
    //           <Failed
    //             title={"Failed"}
    //             description={res.message}
    //             onDismiss={() => {
    //               _this.hide_modal();
    //             }}
    //           />
    //         );
    //       }
    //     });
    //   } else {
    //     return null
    //   }
    // }

    // const emailVerifiedText = () => {
    //   if (_this.state.onboard_data?.isEmailVerified) {
    //     return <strong style={{ color: UI.colors.green}}>Verified</strong>
    //   } else {
    //     return <Button 
    //     disabled={!UI.validate_email(_this.state.email)} 
    //     onClick={() => {
    //       verifyEmailOrPhoneNumber("email")
    //     }} 
    //     style={{ ...UI.validate_email(_this.state.email) && { background: UI.colors.secondary, color: "white", borderRadius: 8 },padding: "0px 10px" }}>
    //       Verify
    //     </Button>
    //   }
    // }

    // const phoneNumberVerifiedText = () => {
    //   if (_this.state.onboard_data?.isPhoneNumberVerified) {
    //     return <strong style={{ color: UI.colors.green}}>Verified</strong>
    //   } else {
    //     return <Button 
    //     disabled={_this.state.contact_no.length !== 10} 
    //     onClick={() => {
    //       verifyEmailOrPhoneNumber("phoneNumber")
    //     }} 
    //     style={{ ..._this.state.contact_no.length === 10 && { background: UI.colors.secondary, color: "white", borderRadius: 8 },padding: "0px 10px" }}>
    //       Verify
    //     </Button>
    //   }
    // }

    return (
      <div _this={_this} className="flex flex-col w-full">
        <OtpCodeModal 
          verifyType={this.state.verifyType}
          _this={_this}
          this={this}
          visible={this.state.isShowOtpModal}
          onCancel={()=> {
            _this.setState({ otpCode: ""})
            _this.setState({ otpErrorMessage: ""})
            this.setState({ isShowOtpModal: false });
          }}
        />
        <div _this={_this} className="flex flex-col gap-3">
          <InputForm
            _this={_this}
            title={"First Name"}
            placeholder={"First Name"}
            state_name={"first_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
          <InputForm
            _this={_this}
            title={"Middle Name"}
            placeholder={"Middle Name"}
            state_name={"middle_name"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
          <InputForm
            _this={_this}
            title={"Last Name"}
            placeholder={"Last Name"}
            state_name={"last_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
        </div>
        {UI.box(10)}
        <div _this={_this} className="flex flex-col gap-3">
          <div className="flex flex-col gap-3 lg:flex-row lg:gap-6 w-full">
            <InputForm
              // additional={emailVerifiedText()}
              _this={_this}
              title={"Email Address"}
              placeholder={"Email Address"}
              state_name={"email"}
              style={{ flex: 1 }}
              isRequired={true}
              disabled={_this.state.onboard_data?.isEmailVerified}
              maxLength={UI.Constants.limits.email}
            />
            <InputForm
              // additional={phoneNumberVerifiedText()}
              _this={_this}
              title={"Contact Number"}
              placeholder={"Contact Number"}
              state_name={"contact_no"}
              prefix={"+63"}
              isRequired={true}
              isNumber={true}
              maxLength={10}
              disabled={_this.state.onboard_data?.isPhoneNumberVerified}
              style={{ flex: 1 }}
            />
          </div>
          <div className="w-full flex flex-col gap-3 lg:flex lg:flex-row lg:gap-6 lg:items-center">
            <Layout.DatePicker
              _this={_this}
              location={this.props.location}
              title={"Date of Birth"}
              additional={age}
              isRequired={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"dob"}
              date_props={{
                maxDate: new Date(),
              }}
              will_correct={true}
            />
            <View style={{ flex: 1 }}>
              <Text style={styles.title}>
                Gender<Text style={styles.asterisk}> *</Text>
              </Text>
              {UI.box(8)}

              <div className="flex flex-row gap-2 mb-7">
                <Checkbox
                  location={this.props.location}
                  _this={_this}
                  text={"Male"}
                  onClick={(value) => {
                    _this.setState({
                      gender: "male",
                    });
                  }}
                  is_checked={gender === "male" ? true : false}
                  color={"#2E2E2E"}
                />
                <Checkbox
                  location={this.props.location}
                  _this={_this}
                  text={"Female"}
                  onClick={(value) => {
                    _this.setState({
                      gender: "female",
                    });
                  }}
                  is_checked={gender === "female" ? true : false}
                  color={"#2E2E2E"}
                />
              </div>
            </View>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <hr></hr>
          <Text style={{ ...styles.header_3, flex: 1 }}>
            Professional & Medical Details
          </Text>
        </div>
        <div _this={_this} className="flex flex-col gap-2 w-full">
          <View>
            <Text style={styles.title}>
              Are you affiliated with a Hospital/Clinic?
            </Text>
            {UI.box(10)}
            <div className="flex flex-col gap-3 lg:flex lg:flex-row lg:gap-6 lg:items-center">
              <Checkbox
                location={this.props.location}
                _this={_this}
                text={"Yes"}
                onClick={(value) => {
                  _this.setState({
                    isAffiliated: true,
                  });
                }}
                is_checked={isAffiliated}
                color={"#2E2E2E"}
              />
              <Checkbox
                location={this.props.location}
                _this={_this}
                text={
                  "No, I'm a solo practitioner/I did not find the Hospital/Clinic from the list."
                }
                onClick={(value) => {
                  _this.setState({
                    isAffiliated: false,
                    // createUserHospital: [],
                    // hospitals: [],
                  });
                }}
                is_checked={!isAffiliated}
                color={"#2E2E2E"}
              />
            </div>
          </View>
          <View style={{ flex: 1, marginTop: 12 }}>
            <Text style={styles.title}>Professional Type</Text>
            {UI.box(8)}

            <div className="flex flex-col gap-3 lg:flex lg:flex-row lg:gap-6">
              <Checkbox
                location={this.props.location}
                _this={_this}
                text={"Medical"}
                onClick={(value) => {
                  _this["multiple_select_specialty"].clearValue();
                  _this.setState({
                    specialty: [],
                    professionalType:
                      _this.state.professionalType === "medical"
                        ? ""
                        : "medical",
                  });
                }}
                is_checked={
                  _this.state.professionalType === true ||
                  _this.state.professionalType === "medical"
                    ? true
                    : false
                }
                color={"#2E2E2E"}
              />
              <Checkbox
                location={this.props.location}
                _this={_this}
                text={"Non Medical"}
                onClick={(value) => {
                  _this["multiple_select_specialty"].clearValue();
                  _this.setState({
                    specialty: [],
                    professionalType:
                      _this.state.professionalType === "Non medical"
                        ? ""
                        : "Non medical",
                  });
                }}
                is_checked={
                  _this.state.professionalType === false ||
                  _this.state.professionalType === "Non medical"
                    ? true
                    : false
                }
                color={"#2E2E2E"}
              />
            </div>
          </View>
          {UI.box(20)}
          <View
            style={{
              display: isAffiliated ? "flex" : "none",
            }}
          >
            <Layout.SelectPicker
              location={this.props.location}
              _this={_this}
              title={"Hospital/Clinic Affiliations"}
              isRequired={true}
              state_name={"createUserHospital"}
              isMultiple={true}
              key={this.state.key}
            >
              <option value="">Select Hospital</option>
              {hospitals.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Layout.SelectPicker>
          </View>
        </div>
        <div _this={_this} className="flex flex-col gap-3 w-full mb-3 mt-3">
          <Layout.SelectPicker
            location={this.props.location}
            _this={_this}
            title={"Languages/Dialects"}
            isRequired={true}
            state_name={"createUserLanguages"}
            isMultiple={true}
          >
            <option value="">Select Language</option>
            {Languages}
          </Layout.SelectPicker>
          <Layout.SelectPicker
            location={this.props.location}
            _this={_this}
            title={"Medical Association"}
            state_name={"createUserMedicalAssociations"}
            isMultiple={true}
          >
            <option value="">Select Medical Association</option>
            {MedicalAssociations}
          </Layout.SelectPicker>
        </div>
      </div>
    );
  }
}

export default withRouter(FirstForm);

const styles = StyleSheet.create({
  title: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#000000",
  },
  header_3: {
    fontSize: 18,
    color: "#000000",
    marginTop: 8,
    marginBottom: 20,
    fontWeight: "bold",
  },
  asterisk: {
    color: "#FF0000",
  },
});
