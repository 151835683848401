import React from 'react'
import styled from 'styled-components';
import LoginForm from './LoginForm';
import UI from '../../shared/Components/UI/js';
import { Row } from 'antd';

const EmailLogin = ({ _this }) => {    
  return (
    <StyledImage image={UI.BG_BLUE_MWELL}>
      <StyledRow>
        <LoginContainer>
          <LoginForm _this={_this}/>
        </LoginContainer>
      </StyledRow>
    </StyledImage>
  )
}

export default EmailLogin

const StyledImage = styled(`div`)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-image: ${(props) => `url(${props.image})`} ;

  @media only screen and (max-width: 800px){
    background-image: none;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginContainer = styled(`div`)`
  border-radius: 10px;
  padding: 47px 32.3px 53px 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 571px;
  width: 100%;
  float: none;
  display: inline-block;
  vertical-align: top;
  background: white;
`;
