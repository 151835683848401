import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import TouchableOpacity, {
  // mem,
  // ScrollView,
  Shadow,
  // StyleSheet,
  View,
  Text,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import { isMobileScreen, isTabletScreens } from "../../constants";

export default class Instructions extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { _this } = this.props;
    return (
      <View
        style={{
          padding: 30,
          minWidth: 100,
          maxWidth: 300,
          borderRadius: 10,
          backgroundColor: "white",
          ...Shadow._3(),
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        <Text style={{ color: "#333333", fontSize: 20, fontWeight: "bold" }}>
          Reminder
        </Text>
        {UI.box(10)}
        <Text style={{ color: "#333333", fontSize: 14, fontWeight: "bold" }}>
          Before signing up, please prepare these required documents that you
          will be uploading during the sign up process: PRC, Profile PIC,
          Signature
        </Text>
        {UI.box(20)}
        <TouchableOpacity
          onClick={() => {
            _this.hide_modal();
          }}
          style={{
            backgroundColor: "#2980b9",
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
            Awesome!
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export class AutoLogout extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { _this } = this.props;
    return (
      <View
        style={{
          padding: 30,
          minWidth: 100,
          maxWidth: 300,
          borderRadius: 10,
          backgroundColor: "white",
          ...Shadow._3(),
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        <Text style={{ color: "#333333", fontSize: 14, fontWeight: "bold" }}>
          {/* Session expired because you were idle for more than {this.props.timer} minutes. */}
          Session expired because you were idle for more than 2 hours.
        </Text>
        {UI.box(20)}
        <TouchableOpacity
          onClick={() => {
            _this.hide_modal();  
            window.location.reload();
            UI.logout();
          }}
          style={{
            backgroundColor: "#2980b9",
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
            Dismiss
          </Text>
        </TouchableOpacity>
      </View>
    ); 
  }
}

export class IncomingCall extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { _this } = this.props;
    return (
      <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10002,
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
      }}
      >
      <div className="absolute" style={{top:'40%', left:'50%'}}>
        <View
          className="shadow-2xl"
          style={{
            padding: 30,
            minWidth: 100,
            maxWidth: 300,
            borderRadius: 10,
            backgroundColor: "white",
            ...Shadow._3(),
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          <Text style={{ color: "rgb(51, 211, 71)", fontSize: 14, fontWeight: "bold", textAlign:'center' }}>
            Incoming Call . . .
          </Text>
          {UI.box(20)}
          <div className="flex gap-3">
            <TouchableOpacity
              onClick={() => {
                _this.accept_call()
              }}
              style={{
                backgroundColor: "#2980b9",
                padding: 10,
                // paddingBottom: 10,
                width: "100%",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
                  Accept
                </Text>
          
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                _this.reject_oncall(_this.state.appointment_id);
                // window.location.reload();
                // window.location.href = "/onboard/dashboard"
              }}
              style={{
                backgroundColor: "rgb(204, 4, 4)",
                padding: 10,
                // paddingBottom: 10,
                width: "100%",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
                  Reject
                </Text>
            </TouchableOpacity>
          </div>
        </View>
      </div>
    </View>
    );
  }
}

export class CancelCLAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const centerModalDesktopView =  { top:'40%', left:'50%' };
    const { _this, dateCellRender, appointmentStatus, isDashboard, selectedAppointment, onSelectAppointment } = this.props;
    console.log(dateCellRender);
    return (
      <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '0px',
        left: '0px',
        alignItems: 'center',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      }}
      >
      <div className="absolute" style={!isMobileScreen && !isTabletScreens ? centerModalDesktopView : {}}>
        <View
          className="shadow-2xl"
          style={{
            padding: 30,
            minWidth: 100,
            maxWidth: 300,
            maxHeight: 600,
            borderRadius: 10,
            backgroundColor: "white",
            ...Shadow._3(),
            paddingTop: 15,
            paddingBottom: 15,
            overflowY: 'auto',
          }}
        >
            {/* for mobile screens: show list of appointments */}
            {isMobileScreen && !isDashboard && (
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                <h3 className="font-semibold">Appointment Lists:</h3>
                {dateCellRender.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => onSelectAppointment(item)}
                    style={{
                      cursor: item.status === "Booked" ? "pointer" : "default",
                      backgroundColor: selectedAppointment?.id === item.id ? "#86efac" : "transparent",
                      paddingBlock: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    <Text>{item.content}</Text>
                  </div>
                ))}
              </div>
            )}

            {/* show cancel input and buttons if a "Booked" appointment is selected */}
            {(selectedAppointment && selectedAppointment.status === "Booked") || (!isMobileScreen && appointmentStatus) || (isDashboard && isMobileScreen) ? (
              <div className="text-center mt-3">
                <span className="font-semibold text-green-500">
                  Are you sure you want to <span className="text-red-500">cancel</span> the appointment?
                </span>
                {!isDashboard && isMobileScreen && 
                  <div className="mt-2">
                    <span>{selectedAppointment.content}</span>
                  </div>
                }
                <View style={{ color: "black", width: "100%", marginBottom: 10 }}>
                  <InputForm
                    _this={_this}
                    title={""}
                    placeholder={"Reason"}
                    state_name={"reason"}
                  />
                </View>
              </div>
            ) : null}

            {UI.box(20)}

            {/* Action Buttons */}
            <div className="flex gap-3">
              {(selectedAppointment && selectedAppointment.status === "Booked") || (!isMobileScreen && appointmentStatus) || (isDashboard && isMobileScreen) ? (
                <TouchableOpacity
                  onClick={async () => {
                    if (!_this.state.reason) {
                      await UI.error_form("reason", "Reason is required.");
                    } else {
                      _this.cancelAppointmnent(_this.state.reason);
                    }
                  }}
                  style={{
                    backgroundColor: "#2980b9",
                    padding: 10,
                    width: "100%",
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
                    Confirm
                  </Text>
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity
                onClick={_this.closeCancelAppointmentModal}
                style={{
                  backgroundColor: "rgb(204, 4, 4)",
                  padding: 10,
                  width: "100%",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
                  {!isMobileScreen && appointmentStatus ? "Cancel" : "Close"}
                </Text>
              </TouchableOpacity>
            </div>
          </View>
        </div>
      </View>
    );
  }
}