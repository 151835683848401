import { Badge, Calendar } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";

import {
  Shadow,
  StyleSheet,
  Text,
  View
} from "../../shared/custom-react-native";
import { CancelCLAppointment } from "../onboard-create-user/Instructions";
import MainLayout from "../_components/MainLayout";
import { Failed } from "../_components/Modal";
import "./css.css";
import { isMobileScreen, isTabletScreens } from "../../constants";

const context = "onboard";
// const default_enddate = "2022-09-29";
// const default_startdate = "1970-01-01";
export default class OnboardConsultLaterCalendar extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Calendar",
      is_drawer_open: false,
      slots: [],
      data:[],

      // start_date_dashed: default_startdate,
      // end_date_dashed: default_enddate,
      appointmentID: null
    });

  }
  
  onCreate = async () => {
    this.load_consultations();
  };

  load_consultations = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/appointments",
      params: {
        // startDate: this.state.start_date_dashed,
        // endDate: this.state.end_date_dashed,
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const newData = data.data.sort((a,b) =>  new Date(b.createdAt) - new Date(a.createdAt));
        console.log(newData)
        this.setState(
          {
            data: newData,
            all_data: newData
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  getColor = (value) => {
    if(value === "Booked") {
      return "success"
    }
    if(value === "Completed") {
      return "success"
    }
    if(value === "Patient_cancelled") {
      return "error"
    }
    if(value === "Practitioner_cancelled") {
      return "error"
    }
    if(value === "Patient_missed") {
      return "warning"
    }
    if(value === "Practitioner_missed") {
      return "warning"
    }
    if(value === "Both_missed") {
      return "warning"
    }
  }

  getListData = (value) => {
    let dateCalendar = UI.getCurrentTimeStampPHConsult(value._d).slice(0, 10);
    let listData = this.state.data.filter(row => dateCalendar === UI.getCurrentTimeStampPHConsult(row.start).slice(0, 10))
    listData = listData.map(row => {
      return {
        id: row._id,
        type: this.getColor(row.status) ,
        status: row.status,
        start: row.start,
        content: row.patient?.name[0].family + " " + row.patient?.name[0].given[0] + " | " + moment(row.start).format("hh:mm a") + " | " + row.status.replace('_', ' '),
      }
    })
    return listData || [];
  };

  handleSelectAppointment = (appointment) => {
    if (appointment.status === "Booked") {
      this.setState({ selectedAppointment: appointment });
    }
  };

  handleViewList = (value) => {
    const listData = this.getListData(value);
    this.setState({ 
      isOpenListData: true,
      listData: listData,
      selectedAppointment: null, // Reset selected appointment 
    });
  }

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul onClick={() => this.handleViewList(value)} className="events">
        {listData.map((item, index) => (
          <li key={index}>
            <Badge 
              status={item.type} 
              text={item.content} 
              onClick={() => {
                if (!isMobileScreen && !isTabletScreens && item.status === "Booked") {
                  this.setState({ 
                    isCancel: true, 
                    appointmentID: item.id,
                  });
                }
            }}/>
          </li>
        ))}
      </ul>
    );
  };

  cancelAppointmnent =  function (reason) {
    this.show_loading();
    this.setState({ isCancel: false, appointmentID: null, isOpenListData: false });
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/doctor/arrived/"+this.state?.appointmentID,
      params: {
        action:  "cancel",
        reason: reason
      },
      onSuccess: async (response) => {
        window.location.href = "/onboard/calendar"
        this.hide_loading();
      },
      onFail: (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      }
    });
  }

  closeCancelAppointmentModal = () => {
    this.setState({ isCancel: false, isOpenListData: false });
  }

  render() {
    const _this = this;
    
    return (
      <MainLayout context={context} _this={_this}>

        {/* For Mobile Screens: Show list of appointments and cancel input/buttons for selected "Booked" appointment */}
        {this.state.isOpenListData && (isMobileScreen || isTabletScreens) && (
          <CancelCLAppointment
            _this={_this}
            dateCellRender={this.state.listData} // pass list data
            selectedAppointment={this.state.selectedAppointment} // pass selected appointment
            onSelectAppointment={this.handleSelectAppointment} // pass selection handler for selecting appointment on mobile
            isDashboard={false} 
          />
        )}

        {/* For Non-Mobile Screens: Directly show cancel input/buttons for "Booked" appointments */}
        {this.state.isCancel && !isMobileScreen && !isTabletScreens && (
          <CancelCLAppointment
            _this={_this}
            appointmentStatus={true} // always show cancel input/buttons
            selectedAppointment={this.state.selectedAppointment} // pass selected appointment
            isDashboard={false}
          />
        )}

        <View style={styles.main_box_content}>
          {this.viewDataListModal}
          <UI.Row style={{ padding: 10 }} breakpoint={1053} _this={_this}>

            <Text style={styles.box_header_text}>Calendar</Text>
          </UI.Row>

          <Calendar 
            mode="month"
            dateCellRender={this.dateCellRender} 
          />
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});
