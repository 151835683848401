import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";
import APIConstants from "../shared/modules/APIConstants";
import { smartech } from "../utils/smartech";
const Constants = new APIConstants();

class AuthApi {
    baseURLApp = Constants.API_HOST;
    async signinUsingEmail (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/login?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;

            smartech("dispatch", "user_logged_in", {
                page_url: window.location.href,
                page_title: document.title
            });

            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    // async resendCode (payload) {
    //     const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/login', payload);
    //     if (response?.status === 200) {
    //         const data = UI.extract_data(response);
    //         data.status = 200;
    //         return data;
    //     } else {
    //         return {
    //             status: response.status,
    //             data: response.data.d,
    //             message: response.data.m
    //         }
    //     }
    // }

    async userGetMe () {
        const response = await ServiceApi.get(this.baseURLApp + 'app/mwell/auth/me');
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async signInUsingMobileNumber (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/mobile-number?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async signInUsingFacebook (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/facebook?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async signInUsingGoogle (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/google?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async signInUsingAppleId (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/apple-id?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyOtpUsingMobileNumber (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/verify-otp?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyMobileNumber (payload) {
        const response = await ServiceApi.put(this.baseURLApp + 'app/mwell/users/send-verify-number?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyOtpNumber (payload) {
        const response = await ServiceApi.put(this.baseURLApp + 'app/mwell/users/otp-verify-number?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyEmail (payload) {
        const response = await ServiceApi.put(this.baseURLApp + 'app/mwell/users/send-verify-email?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyOtpEmail (payload) {
        const response = await ServiceApi.put(this.baseURLApp + 'app/mwell/users/otp-verify-email?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async signupUsingEmail (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/signup', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async verifyOtpUsingEmailSignup (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/otp-verify-email?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async forgetPasswordStep1UsingEmail (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/forgot-password-step-1?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async forgetPasswordStep2UsingEmail (payload) {
        const response = await ServiceApi.post(this.baseURLApp + 'app/mwell/auth/sign/forgot-password-step-2?type=web', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }
}

export default new AuthApi()