import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
import Layout
// , { Asterisk } 
from "../_components/Layout";
import "./css.css";

export default class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_key: "key_" + Date.now(),
    };
  }

  pick_image = async () => {
    const _this = this.props._this;
    let image = await UI.get_doctor_profile_pic();

    _this.setState(
      {
        profile_picture: image.uri,
        profile_file: image.file,
        image_file: image.file,
      },
      () => {
        _this.auth_upload("profile_picture_path", image.file);
      }
    );
    this.setState({
      image_key: "key_" + Date.now(),
    });
  };

  render() {
    const _this = this.props._this;
    const {
      isAffiliated,
      profile_picture,
      file_profile_picture_path,
      hospitals,
      // createUserHospital,
    } = _this.state;

    return (
      <View style={{ width: "100%", paddingBottom: 20 }}>
        <UI.Row _this={_this} breakpoint={720} style={{ width: "100%" }}>
          <Text style={{ ...styles.header_3, flex: 1 }}>
            Profile & Identity Information
          </Text>
        </UI.Row>
        {UI.box(15)}

        <View>
          <View style={{ flex: 1, marginBottom: 10 }}>
          </View>
          <View
            style={styles.createProfileMobile}>
            <View style={styles.image_container}>
              <img
                key={this.state.image_key}
                style={{
                  height: 135 * 0.55,
                  width: 135 * 0.55,
                  borderRadius: 100,
                }}
                src={
                  !file_profile_picture_path
                    ? UI.DOCTOR_PROFILE
                    : profile_picture
                }
                alt=""
              />
              {UI.box(15)}
              <View>
                <Text style={styles.text_1}>
                  {" "}
                </Text>
                {UI.box(5)}
                <Layout.Btn
                  onClick={() => {
                    this.pick_image();
                  }}
                  text={"Upload Profile Picture"}
                  color={UI.colors.primary}
                />
              </View>
              <span className="text-gray-400 mt-2">Recommended image size: 2MB,</span>
              <span className="text-gray-400">Recommended resolution: 170 x 170 pixels</span>
            </View>
            {/* Professional Type */}
            {/* <View style={{ flex: 1 }}>
              <Text style={styles.title}>Professional Type</Text>
              {UI.box(8)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  _this={_this}
                  text={"Medical"}
                  onClick={(value) => {
                    _this["multiple_select_specialty"].clearValue();
                    _this.setState({
                      specialty: [],
                      professionalType:
                        _this.state.professionalType === "medical"
                          ? ""
                          : "medical",
                    });
                  }}
                  is_checked={
                    _this.state.professionalType === true ||
                    _this.state.professionalType === "medical"
                      ? true
                      : false
                  }
                  color={"#2E2E2E"}
                />
                <View style={{ width: 50 }}></View>
                <Checkbox
                  _this={_this}
                  text={"Non Medical"}
                  onClick={(value) => {
                    _this["multiple_select_specialty"].clearValue();
                    _this.setState({
                      specialty: [],
                      professionalType:
                        _this.state.professionalType === "Non medical"
                          ? ""
                          : "Non medical",
                    });
                  }}
                  is_checked={
                    _this.state.professionalType === false ||
                    _this.state.professionalType === "Non medical"
                      ? true
                      : false
                  }
                  color={"#2E2E2E"}
                />
              </View>
            </View> */}
          </View>
        </View>
        {/* are you affiliated */}
        {/* {UI.box(20)}
        <UI.Row _this={_this} breakpoint={1000}>
          <View>
            <Text style={styles.title}>
              Are you affiliated with a Hospital/Clinic?
            </Text>
            {UI.box(15)}

            <UI.Row _this={_this} breakpoint={1070}>
              <Checkbox
                _this={_this}
                text={"Yes"}
                onClick={(value) => {
                  _this.setState({
                    isAffiliated: true,
                  });
                }}
                is_checked={isAffiliated}
                color={"#2E2E2E"}
              />
              {UI.box(10)}
              <Checkbox
                _this={_this}
                text={
                  "No, I'm a solo practitioner/I did not find the Hospital/Clinic from the list."
                }
                onClick={(value) => {
                  _this.setState({
                    isAffiliated: false,
                    // createUserHospital: [],
                    // hospitals: [],
                  });
                }}
                is_checked={!isAffiliated}
                color={"#2E2E2E"}
              />
            </UI.Row>
          </View>
          {UI.box(20)}
          <View
            style={{
              display: isAffiliated ? "flex" : "none",
            }}
          >
            <Layout.SelectPicker
              _this={_this}
              title={"Hospital/Clinic Affiliations"}
              isRequired={true}
              state_name={"createUserHospital"}
              isMultiple={true}
              key={this.state.key}
            >
              <option value="">Select Hospital</option>
              {hospitals.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Layout.SelectPicker>
          </View>
        </UI.Row> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  createProfileMobile: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    flexDirection: "column",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: "#000000",
    marginTop: 2,
    fontWeight: "bold",
  },
});