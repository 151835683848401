import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  // StyleSheet,
   View 
  } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout, { PreviewFile } from "../_components/Layout";
// import { CustomConfirmation } from "../_components/Modal";
import "./css.css";
import APIConstants from "../../shared/modules/APIConstants";
import DigitalESignature from "../../ComponentsFC/onboard/create/DigitalESignature";

const Constants = new APIConstants();
export default class ThirdForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      // file_prc_license_path,
      file_signature_path,
      file_cor_path
    } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
      >
        <div _this={_this} className="flex flex-col gap-3 w-full mt-3">
          <InputForm
            _this={_this}
            title={"Price Per Consultation"}
            placeholder={"Price Per Consultation"}
            state_name={"consultation_fee"}
            isRequired={true}
            isNumber={true}
            maxLength={13}
            is_onblur={true}
            onblur_message={Constants.consultation_fee_validation}
            style={{ flex: 1 }}
          />
          <View style={{ flex: 1 }}>
            <DigitalESignature _this={_this} />
            <PreviewFile _this={_this} {...file_signature_path} />
          </View>
          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"BIR Form 2303 (Certificate of Registration)"}
              isRequired={false}
              additional={
                <View style={{ flexDirection: "row" }}>
                  (.jpeg, .png or .pdf only)
                  {/* <TouchableOpacity
                    onClick={() => {
                      _this.download_file_object(downloaded_cor);
                    }}
                    style={styles.download_button}
                  >
                    <Text style={styles.download_text}>Download</Text>
                  </TouchableOpacity> */}
                </View>
              }
              text={"Drop Your File Here"}
              state_name={"cor"}
              onChange={(file) => {
                _this.auth_upload("cor_path", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            />

            <PreviewFile _this={_this} {...file_cor_path} />
          </View>
          {/* <View style={{ width: "100%" }}>
          </View> */}
        </div>
      </UI.Column>
    );
  }
}

// const styles = StyleSheet.create({
//   file_text: {
//     fontSize: 15,
//     fontWeight: "bold",
//     color: UI.colors.secondary,
//   },
//   file_container: {
//     width: "100%",
//     height: 80,
//     backgroundColor: "#EFF3F7",
//     borderRadius: 8,
//     borderWidth: 1,
//     borderColor: "#C9C9C9",
//     borderStyle: "dashed",
//     flexDirection: "row",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   fade: {
//     color: "#BFBFBF",
//   },
//   asterisk: {
//     color: "#FF0000",
//   },
//   option_container: {
//     height: 40,
//     width: "100%",
//     borderRadius: 5,
//     borderStyle: "solid",
//     borderWidth: 2,
//     padding: 5,
//     borderColor: "#CACACA",
//   },
//   title: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
//   text_2: {
//     fontSize: 14,
//     color: "#D6D6D6",
//   },
//   btn_text_1: {
//     fontSize: 14,
//     color: "white",
//     fontWeight: "bold",
//   },
//   blue_btn_2: {
//     paddingLeft: 15,
//     paddingRight: 15,
//     paddingTop: 12,
//     paddingBottom: 12,
//     backgroundColor: UI.colors.primary,
//     borderRadius: 8,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   text_1: {
//     color: "#AFAFAF",
//     fontSize: 14,
//   },
//   image_container: {
//     alignItems: "center",
//     flexDirection: "row",
//     alignSelf: "flex-start",
//   },
//   header_3: {
//     fontSize: 18,
//     color: UI.colors.primary,
//     marginTop: 2,
//     fontWeight: "bold",
//   },
// });