import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class AdminsApi {

    baseURL = 'admin';

    async getAllRegions (id) {
        const response = await ServiceApi.get(this.baseURL+`/public/regions`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async downloadDoctorLegalFiles (fileId) {
        console.log(fileId);
        const response = await ServiceApi.getBlobFile(`admin/doctors/download/files?fileId=${fileId}`);
        if (response?.status === 200) {
            const contentDisposition = response.headers["content-disposition"];
            console.log(contentDisposition)
            const matches = contentDisposition && contentDisposition.match(/filename(?:\*?)\s*=\s*(?:['"]?)([^'";\n]+)(?:['"]?)/);
            console.log(matches);
            const fileName = matches && matches[1] ? matches[1] : "downloaded-file.pdf";
            console.log(fileName);

            const blob = new Blob([response.data], { type: 'application/pdf' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);  // Set the filename
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);  // Clean up the URL after download
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
              }
        }
      }

}

export default new AdminsApi()