import React, { useState } from 'react'
import InputNumericField from '../ui/forms/InputNumericField'
import Text from '../ui/Text'
import FormItem from '../ui/forms/FormItem'
import FieldLabel from '../ui/forms/FieldLabel'
import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'
import SelectField from '../ui/forms/SelectField'
import Loading from "../../modules/_components/Loading";
import UI from '../../shared/Components/UI/js'
import ServiceAuthApi from '../../api_services/auth'
import ProcessFailedModal from '../ui/modals/ProcessFailedModal'
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross'
import { useEffect } from 'react'
// import axios from 'axios'
import countries from '../../shared/mockAPI/countries.json'
import _ from 'lodash'
import { Image as AntdImage } from 'antd';
import jwt from 'jsonwebtoken'

const LoginForm = () => {
  const [value, setValue] = useState("");
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    name: "Philippines",
    flag: "🇵🇭",
    code: "ph",
    dial_code: "+63"
  });


  const login = (e) => {

    const payload = {
      ...e,
      role: "provider",
      country: selectedCountryCode.code.toUpperCase(),
      phoneNumberPrefix: selectedCountryCode.dial_code,
    };

    setIsScreenLoading(true);
    ServiceAuthApi.signInUsingMobileNumber(payload).then(res => {
      if (res.status === 200) {
        console.log(res);
        setIsScreenLoading(false);
        jwt.sign({ ...payload, userId: res.user }, 'secret')
        UI.goTo(`/onboard/otp?credentials=${jwt.sign({ ...payload, userId: res.user }, 'secret')}`);
      } else {
        setIsScreenLoading(false);
        setIsFailed(true);
        setErrorMessage(res.message);
      }
    });
  };

  const getCountries = async () => {
    const newData = countries.map((country, index) => {
      const code = country.code.toLowerCase()
      return {
        ...country,
        index: index,
        name: country.dial_code,
        value: country.dial_code,
        type: <>
          <AntdImage
            style={{ borderRadius: "0%", display: "inline-block", margin: "0 5px", width: 20 }}
            src={`https://hatscripts.github.io/circle-flags/flags/${code}.svg`}
            alt=""
            preview={false}
          /> <strong>{country.code}</strong> <span style={{ color: UI.colors.primary }}>{country.dial_code}</span>
        </>,
        code: code,
      };
    });
    setCountriesData(_.orderBy(newData, ['code'], ['asc']));
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Logging In...`}
        modal_opaque={false}
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => setIsFailed(false)}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <LoginFormContainer>
        <StyledText level={4}> Register or login with your Mobile Number </StyledText>
        <Form
          layout={"vertical"}
          onFinish={(e) => {
            login(e)
          }}
        >
          <Col>
            <FieldLabel style={{ color: '#565656', fontSize: "20px", fontWeight: 500 }}>Mobile Number <strong style={{ color: "red" }}>*</strong></FieldLabel>
            <Row className='gap-3'>
              <FormItem>
                <StyledSelectField
                  showArrow={false}
                  style={{ width: 125, height: "100%" }}
                  value={<>
                    <AntdImage
                      style={{ display: "inline-block", margin: "0 5px", width: 20 }}
                      preview={false}
                      src={`https://hatscripts.github.io/circle-flags/flags/${selectedCountryCode.code}.svg`}
                      alt=""
                    /> {selectedCountryCode.dial_code}</>
                  }
                  selectoptions={countriesData}
                  onChange={(value) => {
                    const find = countriesData.find(country => country.value === value)
                    setSelectedCountryCode(find)
                  }}
                />
              </FormItem>
              <FormItem
                style={{ display: "flex", alignSelf: "end" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Mobile Number is required",
                  },
                  () => ({
                    validator(_, value) {
                      //This allows mobile number minimum length
                      if (selectedCountryCode.code === "ph") {
                        if (value && value.length < 10) {
                          return Promise.reject(
                            "Mobile Number can't be less than 10 digits"
                          );
                        }
                        if (value && value.length > 10) {
                          return Promise.reject(
                            "Mobile Number can't be more than 10 digits"
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <StyledInputNumericField
                  style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
                  placeholder="Mobile Number"
                  value={value}
                  onChange={setValue}
                />
              </FormItem>
            </Row>
          </Col>

          <FormItem>
            <StyledButton
              id="submit-button"
              htmlType="submit"
            >
              Continue
            </StyledButton>
          </FormItem>

        </Form>
      </LoginFormContainer>
    </>

  )
}

export default LoginForm

const LoginFormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;

  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;

  &.ant-btn[disabled] { 
    background-color: rgb(199, 199, 199);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #004F99;
    color: #ffffff;
    border-color: #004F99;
  }
`;

const StyledSelectField = styled(SelectField)`
  .ant-select-selector {
    border: 2px solid #DFE5EB !important;

    & .ant-select-selection-search-input {
      height: 100% !important;
      text-align: center;
      background: red !important;
    }

    & .ant-select-selection-item {
      display: flex;
      justify-content: space-around;
    }

    & .ant-select-selection-item input {
      background: red !important;
    }
  }

`;

const StyledInputNumericField = styled(InputNumericField)`
  .ant-select-selector {
    border: 2px solid #DFE5EB !important;
  }
`;

const StyledText = styled(Text)`
  color:#727D8A !important;
  text-align: center;
  line-height: 30px !important;
`