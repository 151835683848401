import React from 'react'
import InputForm from "../_components/InputForm";
import UI from "../../shared/Components/UI/js";
import Layout from "../_components/Layout";

const PTRForm = ({ _this }) => {
  return (
    <div _this={_this} className='w-full'>
      {UI.box(10)}
      <div className='flex flex-col gap-3 lg:flex lg:flex-row lg:gap-8 lg:items-center'>
        <div className='flex-1'>
          <Layout.FilePicker
            _this={_this}
            title={"Upload PTR"}
            additional={"(jpeg or png)"}
            text={"Drop Your File Here"}
            state_name={"ptr"}
            onChange={async (file) => {
              _this.auth_upload("ptr", file);
            }}
            allowed_files={["jpg", "jpeg", "png"]}
            display_type={"filename_size"}
          />
        </div>
        <InputForm
          _this={_this}
          title={"PTR Number"}
          placeholder={"PTR Number"}
          state_name={"ptrNumber"}
          style={{ flex: 1 }}
        />
      </div>
    </div>
  )
}

export default PTRForm;