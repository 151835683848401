import React from "react";
import UI from "../../shared/Components/UI/js";
import { Text, View } from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import { AgreementConfirmation } from "../_components/Modal";
import MarketingConsentModal from "./Modal/MarketingConsent";
import MarketingConsentPDF from "./PDF/MarketingConsent";
import { pdf } from "react-pdf-renderer-v2";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MarketingConsent = ({ _this }) => {
  const location = useLocation(); 
  const isDoctorOnboarding = location.pathname.includes("/onboard/create-user");

  return (
    <div className="flex flex-col gap-2 w-full">
      <Text
        style={{
          color: isDoctorOnboarding ? "#000000" : UI.colors.primary,
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Marketing Consent{" "}
        <span style={{ color: "rgb(255, 0, 0)", opacity: 1 }}>*</span>
      </Text>
      <Text style={{ color: "#9ca3af", fontSize: 14, fontWeight: "bold", paddingBottom: 4 }}>
        Please sign the Marketing Consent.
      </Text>
      <div className="w-3/5">
        <Layout.Btn
          color={UI.colors.primary}
          onClick={() => {
            _this.show_modal(
              <AgreementConfirmation
                isConfirm={_this.state.confirmMarketingConsent}
                setIsConfirm={(cb) =>
                  _this.setState({ confirmMarketingConsent: cb })
                }
                title="Marketing Consent"
                checkboxTitle="By clicking this box, I agree to the terms and conditions of the Marketing Consent Form and to displaying my e-signature on it"
                onOk={{
                  text: "Proceed",
                  method: () => {
                    pdf(<MarketingConsentPDF _this={_this} />)
                      .toBlob()
                      .then((response) => {
                        return response;
                      })
                      .then((myBlob) => {
                        var file = new File([myBlob], "Marketing-Consent.pdf");

                        _this.auth_upload("marketing_consent_path", file);
                      });

                    _this.hide_modal();
                  },
                }}
                onDismiss={() => {
                  _this.hide_modal();
                }}
              >
                <MarketingConsentModal _this={_this} />
              </AgreementConfirmation>
            );
          }}
          width="100%"
          text={"View and sign Marketing Consent"}
        />
      </div>
    </div>
  );
};

export default MarketingConsent;