import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { 
  // StyleSheet, 
  Text, View } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";

export default class FourthForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const { 
      // height, 
      width,
      // isMobile
     } = _this.state;
    return (
      <div className="flex flex-col w-full mt-5">
        <hr></hr>
        <div _this={_this} className="mt-4 w-full">
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#000000",
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            Financial Information
          </Text>

          <div _this={_this} className="flex flex-col gap-3">
            <Layout.SelectPicker
              _this={_this}
              title={""}
              // isRequired={true}
              onChange={(e) => {
                _this.setState({ financialType: e });
              }}
              state_name={"financialType"}
            >
              <option value="">Select Type of Financial</option>
              <option value="bank">Bank</option>
              <option value="paymaya">Maya</option>
            </Layout.SelectPicker>
            {UI.box(10)}
          </div>
          {_this.state.financialType === "bank" && (
            <div _this={_this} className="flex flex-col w-full">
              <InputForm
                _this={_this}
                title={"Bank Name"}
                placeholder={"Bank Name"}
                state_name={"bankName"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Bank Branch"}
                placeholder={"Bank Branch"}
                state_name={"bankBranch"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <div className="flex flex-col gap-2 w-full">
                <InputForm
                  _this={_this}
                  title={"Name on the Bank Account"}
                  placeholder={"Name on the Bank Account"}
                  state_name={"bankAccountName"}
                  style={{ flex: 1 }}
                  isRequired={true}
                  maxLength={UI.Constants.limits.name}
                />
                <InputForm
                  _this={_this}
                  title={"Bank Account Number"}
                  placeholder={"Bank Account Number"}
                  state_name={"bankAccountNumber"}
                  isRequired={true}
                  isNumber={true}
                  style={{ flex: 1 }}
                  maxLength={UI.Constants.limits.bank_account_number}
                />  
              </div>
            </div> 
          )}

          {_this.state.financialType === "paymaya" && (
            <div _this={_this} className="w-full">
              <InputForm
                _this={_this}
                title={"Maya Account Number"}
                placeholder={"Maya Account Number"}
                state_name={"paymayaAccountNumber"}
                isRequired={true}
                isNumber={true}
                style={{ flex: 1 }}
                maxLength={UI.Constants.limits.bank_account_number}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}