import React from 'react'
import InputForm from "../_components/InputForm";
import UI from "../../shared/Components/UI/js";
import { View, Text } from "../../shared/custom-react-native";
import Layout, { PreviewFile } from "../_components/Layout";

const S2Form = ({ _this }) => {
  const { 
    file_mpc 
  } = _this.state;
  return (
    <div _this={_this} className='flex flex-col gap-3 mt-3'>
      <div className='flex flex-col gap-3 lg:flex lg:flex-row lg:gap-8 lg:items-center'>
        <div className='flex-1'>
          <Layout.FilePicker
            _this={_this}
            title={"Upload S2"}
            additional={"(jpeg or png)"}
            text={"Drop Your File Here"}
            state_name={"s2"}
            onChange={async (file) => {
              _this.auth_upload("s2", file);
            }}
            allowed_files={["jpg", "jpeg", "png"]}
            display_type={"filename_size"}
          />
        </div>
        <InputForm
          _this={_this}
          title={"S2 Number"}
          placeholder={"S2 Number"}
          state_name={"s2Number"}
          style={{ flex: 1 }}
        />
      </div>
      <div className=''>
        <Layout.FilePicker
          _this={_this}
          title={"Upload Medical Practice Document"}
          // isRequired={true}
          text={"Drop Your File Here"}
          state_name={"mpc"}
          onChange={(file) => {
            _this.auth_upload("mpc", file);
          }}
          allowed_files={["jpg", "jpeg", "pdf", "png"]}
          display_type={"filename_size"}
        />
        {UI.box(10)}
        <Text
          style={{
            width: "90%",
            fontSize: 14,
            fontWeight: "bold",
            color: "rgb(191, 191, 191)",
          }}
        >
          Any certificate that shows additional level of learning received
          like being a Diplomate or Fellow.
        </Text>
        <PreviewFile _this={_this} {...file_mpc} />
      </div>
    </div>
  )
}

export default S2Form;