import React, { useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import DropdownIcon from "../../../icons/Dropdown";

const SelectField = React.memo((props) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledSelect
      {...props}
      getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
      suffixIcon={<DropdownIcon onClick={() => setOpen(!open)} />}
      open={open}
      onClick={() => setOpen(!open)}
      onBlur={() => setOpen(false)}
      onClear={() => setOpen(false)}
    >
      {props?.selectoptions?.map((el, index) => (
        <Select.Option key={index} value={el.value}>
          {el.type}
        </Select.Option>
      ))}
    </StyledSelect>
  );
});

export default SelectField;

const StyledSelect = styled(Select)`
  width: 100%;
  @media(min-width: 1024px) {
    width: 441px;
  }
  .ant-select-selector {
    border-radius: 12px !important;
    border: 2px solid #cacaca !important;

    height: 60px !important;
    align-items: center;
    font-size: 1.25rem; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
    & .ant-select-selection-placeholder {
      color: #cacaca;
      /* padding-right: 30px; */
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
      height: 50px !important;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 35%;
    right: 10px;
    font-size: 21px;
    opacity: 1 !important;
    color: #cacaca;
    width: 25px;
    height: 25px;
  }
`;
