import React, { useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import "./App.css";
import AppRouter from "./AppRouter";
import ErrorBoundary from "./modules/_components/ErrorBoundary";
import { smartech } from "./utils/smartech";

export default function App() {
  const GlobalScript = () => {
    const location = useLocation();

    useEffect(() => {
      smartech("dispatch", "page browse", {
        page_url: window.location.href,
        page_title: document.title,
      })
    }, [location]);

    return null;
  }

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AppRouter />
        <GlobalScript/>
      </ErrorBoundary>
    </BrowserRouter>
  );
}