import React, { useState, useEffect } from "react";
import { Avatar, Row, Button, Table, Typography } from "antd";
import {
  ArrowLeftOutlined, //prevPage
  ArrowRightOutlined, //nextPage
  MinusOutlined, //quickJumperDecrease
  PlusOutlined, //quickJumperIncrease
  CheckOutlined, //quickJumperGoTo
} from "@ant-design/icons";
import styled from "styled-components";
import UI from "../shared/Components/UI/js";
import { isMobileScreen } from "../constants";


const DataTable = (props) => {
  const { 
    tableColumnHeader, 
    dataSource, 
    defaultPageSize, 
    tableEntries,
    pageSizeOptions,
    isBackendPaginated,
    totalRecords,
    onPaginate,
    hideTotalSummaryOnSinglePage = false,
    hidePaginationOnSinglePage = false
  } = props;

  // Table Summary State by antd default
  const [currTablePageStartRange, setCurrTablePageStartRange] = useState(null);
  const [currTablePageEndRange, setCurrTablePageEndRange] = useState(null);
  const [currTablePageTotalEntries, setCurrTablePageTotalEntries] = useState(null);
  // Table Pagination State
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [inputPageNum, setInputPageNum] = useState(1);
  const [isPageQuickJumperOpen, setIsPageQuickJumperOpen] = useState(false);
  const [isPageNum1Invisible, setIsPageNum1Invisible] = useState(false);

  const totalPageCreatedByAntd = Math.ceil(
    currTablePageTotalEntries / tableEntries
  );

  useEffect(()=> {
    setIsPageQuickJumperOpen(false);
  }, [tableEntries])

  const noDataAvailable = (
    <FlexBox>
      <Avatar
        size={{
          xs: 24,
          sm: 32,
          md: 40,
          lg: 64,
          xl: 80,
          xxl: 100,
        }}
        src={UI.NO_INFO}
      />
      <NoDataText>NO DATA AVAILABLE YET</NoDataText>
    </FlexBox>
  );

  // THE ENTIRE ANTD TABLE PAGINATION CONFIGURATION ------------------
  // Quick jumper input field
  const handleChangePageNum = (e) => {
    // Avoid special characters and letters
    if (!(/^[0-9]+$/.test(e.target.value))) return;
    // Avoid 0 in input field and always put the last page if input > total pages
    if (e.target.value < 1) {setInputPageNum(currentPageNum); return}
    if (e.target.value > totalPageCreatedByAntd) {setInputPageNum(totalPageCreatedByAntd); return}
    setInputPageNum(Number(e.target.value));
  };

  const handleIncreasePageNum = () => {
    // Don't increase if it reaches last page
    if (inputPageNum === Math.ceil(currTablePageTotalEntries / tableEntries))
      return;
    setInputPageNum((prev) => prev + 1);
  };
  const handleDecreasePageNum = () => {
    if (inputPageNum <= 1) return;
    setInputPageNum((prev) => prev - 1);
  };

  const handleChangeTablePagination = (page) => {
    setCurrentPageNum(page);
    setInputPageNum(page);
    onPaginate(page);
  };

  // Logic for Custom Pagination
  const customPrevNextAndPageNumPaginationBtn = (
    pageNumber,
    type,
    originalElement
  ) => {
    // By default Antd pagination
    // - shows 5 page numbers at a time, also if total records > 50
    // - shows jump-prev and jump-next ellipsis if totalPageCreatedByAntd >= 8
    // - 3rd page from the last removes ellipsis (jump-next) if total records > 50
    // - removes jump-next ellipsis if 3rd to the last present page is selected (e.g. If last page is #10 then #7 is selected, or last page #20 then #17 and so on...)
    // - total records of data / shown table entries = 1 page (e.g. 10 / 5 = 2 pages or 8 / 5 = 2 pages)

    const activePage = originalElement._owner?.memoizedProps?.active;
    const the3rdPageFromTheLast = totalPageCreatedByAntd - 3;
    const allTableEntries =
      tableEntries === 5 || tableEntries === 10 || tableEntries === 30 || tableEntries === 100;
    // const pageNum1 = document.querySelector(`li.ant-pagination-item-${1}`);
    const pageNum4 = document.querySelector(`li.ant-pagination-item-${4}`);
    const pageNum5 = document.querySelector(`li.ant-pagination-item-${5}`);
    const pageNum6 = document.querySelector(`li.ant-pagination-item-${6}`);
    const pageNum7 = document.querySelector(`li.ant-pagination-item-${7}`);
    const mainPaginationElement = document.querySelector(
      "ul.ant-table-pagination.ant-pagination"
    );
    const improvisedLi = document.querySelector(
      "li.ant-pagination-item-jump-next.improvised"
    );

    const onePageAboveTheCurrent = (pageNum) =>
      document.querySelector(`li.ant-pagination-item-${pageNum + 1}`);
    const onePageBelowTheCurrent = (pageNum) =>
      document.querySelector(`li.ant-pagination-item-${pageNum - 1}`);
    const twoPagesAboveTheCurrent = (pageNum) =>
      document.querySelector(`li.ant-pagination-item-${pageNum + 2}`);
    const twoPagesBelowTheCurrent = (pageNum) =>
      document.querySelector(`li.ant-pagination-item-${pageNum - 2}`);
    const lastPageOfTable = (pageNum) =>
      document.querySelector(`li.ant-pagination-item-${pageNum}`);

    // console.log("Total Entries of currTablePage: ", currTablePageTotalEntries);
    // console.log("Total Pages Created by Ant D: ", totalPageCreatedByAntd);
    // console.log("The 3rd page from the last: ", the3rdPageFromTheLast);
    // console.log("PAGE ELEMENT: ", originalElement);
    // console.log("PAGE TYPE: ", type);
    // console.log("PAGE NUMBER: ", pageNumber);
    // console.log("PAGE ACTIVE: ", activePage);

    // LOGIC FOR TOTAL PAGE < 8
    // Table Pagination Behavior for records > 50
    // if (currTablePageTotalEntries >= 50) {
    if (totalPageCreatedByAntd >= 8) {
      // Always remove the marginLeft when switching from total table pages < 8
      if (pageNum4) {
        pageNum4.style.marginLeft = "0";
      }
      if (pageNum5) {
        pageNum5.style.marginLeft = "0";
      }
      if (pageNum6) {
        pageNum6.style.marginLeft = "0";
      }
      if (pageNum7) {
        pageNum7.style.marginLeft = "0";
      }

      // Auto-set default margin right created from previous table entries when switching (Only Show 5, 10, 30)
      if (pageNumber && activePage === true) {
        if(onePageBelowTheCurrent(pageNumber)) {onePageBelowTheCurrent(pageNumber).style.marginRight="8px";}
        // w/ Except the last page
        if(onePageAboveTheCurrent(pageNumber) && pageNumber !== totalPageCreatedByAntd - 1) {onePageAboveTheCurrent(pageNumber).style.marginRight="8px";}
      }

      // Remove default page #4 and #5
      if (allTableEntries && pageNumber === 1 && activePage === true) {
        setIsPageNum1Invisible(false);
        if (onePageAboveTheCurrent(pageNumber)){
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        }
        if (twoPagesAboveTheCurrent(pageNumber)) {
          twoPagesAboveTheCurrent(pageNumber).style.display = "inline-block";
        }
        if (pageNum4 && pageNum5) {
          pageNum4.style.display = "none";
          pageNum5.style.display = "none";
        }
      }

      // Logic for Table Entries set to 5
      // if (tableEntries === 5) {
      if (pageNumber >= 3 && activePage === true) {
        setIsPageNum1Invisible(true);
      }

      if (pageNumber === 2 && activePage === true) {
        setIsPageNum1Invisible(false);
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        }
        if (pageNum4 && pageNum5) {
          pageNum4.style.display = "none";
          pageNum5.style.display = "none";
        }
      }

      // If Page #3 is active, display #5, #4 and #2
      if (pageNumber === 3 && activePage === true) {
        onePageBelowTheCurrent(pageNumber).style.display = "inline-block";
        onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        if ( totalPageCreatedByAntd !== 5 && twoPagesAboveTheCurrent(pageNumber)) {
          twoPagesAboveTheCurrent(pageNumber).style.display = "none";
        }
      }

      // If Page # >= 4 is active, all page # must dynamically change by 3's (customize antd defaults)
      // (e.g. if Page #5 is active, remove 2nd page above #5 and 2nd page less #5)
      if (pageNumber >= 4 && activePage === true) {
        // console.log("Current Page Number >= 4: ", pageNumber - 2, pageNumber + 2);
        setIsPageNum1Invisible(true);
        if (pageNumber >= totalPageCreatedByAntd - 2 && activePage === true) {
          if (onePageAboveTheCurrent(pageNumber)) {
            onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
            onePageAboveTheCurrent(pageNumber).style.marginRight = "48px";
          }
          if (pageNumber !== totalPageCreatedByAntd) {
            onePageAboveTheCurrent(pageNumber - 1).style.marginRight = "8px";
            twoPagesBelowTheCurrent(pageNumber).style.display = "none";
          }
        }

        if (pageNumber >= the3rdPageFromTheLast && activePage === true) {
          // Ensure margins of 2nd & 1st page from the last page is cleared
          // lastPageOfTable(totalPageCreatedByAntd - 1).style.marginRight = "8px";
          if (lastPageOfTable(totalPageCreatedByAntd - 2)) {
            lastPageOfTable(totalPageCreatedByAntd - 2).style.marginRight = "8px";
          }
          // Create space on the right of the 2nd page # before the last page #
          if (onePageAboveTheCurrent(pageNumber)) {
            onePageAboveTheCurrent(pageNumber).style.marginRight = "48px";
            onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
          }
          // lastPageOfTable(totalPageCreatedByAntd).style.marginRight = "15px";
          // Create & Insert Improvised Li > Ellipsis Button (Styles in styled components below this FC)
          if (improvisedLi === null) {
            let li = document.createElement("li");
            li.className = "ant-pagination-item-jump-next improvised";
            let btn = document.createElement("button");
            btn.className = "ant-btn ant-btn-link improvised";
            btn.innerHTML = "...";
            btn.addEventListener("click", () => {
              setIsPageQuickJumperOpen((prevBool) => !prevBool);
            });
            li.appendChild(btn);
            mainPaginationElement.appendChild(li);
          }
        } else if (pageNumber < the3rdPageFromTheLast && activePage === true) {
          if (improvisedLi) {
            mainPaginationElement.removeChild(improvisedLi);
          }
        }

        // Except last page
        if (
          twoPagesAboveTheCurrent(pageNumber) &&
          pageNumber !== totalPageCreatedByAntd - 2
        ) {
          twoPagesAboveTheCurrent(pageNumber).style.display = "none";
        }
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        }

        twoPagesBelowTheCurrent(pageNumber).style.display = "none";
        onePageBelowTheCurrent(pageNumber).style.display = "inline-block";

        // Logic for Last Page is Active in Total Pages >= 8
        // By antd default when last page or page before last is active all last 5 pages is displayed
        // Prevent the 2nd to the last page from disappearing, if last page is active
        if ((pageNumber === totalPageCreatedByAntd || pageNumber === totalPageCreatedByAntd - 1) && activePage === true) {
          twoPagesBelowTheCurrent(pageNumber).style.display = "inline-block";
          if(lastPageOfTable(totalPageCreatedByAntd)){lastPageOfTable(totalPageCreatedByAntd).style.marginRight = "15px";}
          // Remove the 4th to the last page
          if (lastPageOfTable(totalPageCreatedByAntd - 4)) {lastPageOfTable(totalPageCreatedByAntd - 4).style.display = "none";}
          // Ensure the 1st to the last page creates space for ellipsis
          if(lastPageOfTable(totalPageCreatedByAntd - 1)){lastPageOfTable(totalPageCreatedByAntd - 1).style.marginRight = "48px";}
          // Ensure 3rd from the last page is displayed
          if(lastPageOfTable(totalPageCreatedByAntd - 3)){lastPageOfTable(totalPageCreatedByAntd - 3).style.display = "inline-block";}
        }
      }
      // }
    }

    // LOGIC FOR TOTAL PAGE < 8
    if (totalPageCreatedByAntd < 8) {
      // Adds space on the left for the ellipsis btn for Total Pages >= 4
      if (lastPageOfTable(totalPageCreatedByAntd) && totalPageCreatedByAntd >= 4 ) {
        lastPageOfTable(totalPageCreatedByAntd).style.marginLeft = "40px";
      }

      // Always show Page 1 if Total Pages === 4 (e.g. 1 2 3 ... 4)
      if (totalPageCreatedByAntd <= 4) {
        setIsPageNum1Invisible(false);
      }

      // Only execute this if Total Pages === 7
      if (totalPageCreatedByAntd === 7) {
        if (pageNumber === 5 && activePage === true) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
          twoPagesBelowTheCurrent(pageNumber - 1).style.display = "none";
        }
        if (pageNumber !== 5 && activePage === true) {
          if (onePageBelowTheCurrent(totalPageCreatedByAntd)) {
            onePageBelowTheCurrent(totalPageCreatedByAntd).style.display = "none"; //page #6
          }
        }
      }

      // Create & Display Improvised li
      if (improvisedLi === null && totalPageCreatedByAntd >= 4) {
        let li = document.createElement("li");
        li.className = "ant-pagination-item-jump-next improvised";
        let btn = document.createElement("button");
        btn.className = "ant-btn ant-btn-link improvised";
        btn.innerHTML = "...";
        btn.addEventListener("click", () => {
          setIsPageQuickJumperOpen((prevBool) => !prevBool);
        });
        li.appendChild(btn);
        if (mainPaginationElement) {
          mainPaginationElement.appendChild(li);
        }
      }
      // Remove improvised li if total page <= 3
      if (totalPageCreatedByAntd <= 3) {
        if (mainPaginationElement && improvisedLi) {
          mainPaginationElement.removeChild(improvisedLi);
        }
      }

      // Initial Page Numbers
      if (allTableEntries && pageNumber === 1 && activePage === true) {
        setIsPageNum1Invisible(false);
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block"; // #2
        }
        if (twoPagesAboveTheCurrent(pageNumber)) {
          twoPagesAboveTheCurrent(pageNumber).style.display = "inline-block"; // #3
        }
        // Show #4 and #5 if switching from Total Pages < 8 to Total Pages >= 8 then back
        if (pageNum4 && pageNum5) {
          pageNum4.style.display = "inline-block";
          pageNum5.style.display = "inline-block";
        }
        if (pageNum4 && pageNum5 && totalPageCreatedByAntd >= 6) {
          pageNum4.style.display = "none";
          pageNum5.style.display = "none";
        }
        if (pageNum4 && totalPageCreatedByAntd === 5) {
          pageNum4.style.display = "none";
        }
      }

      // Logic for Table Entries set to 5
      // if (tableEntries === 5) {
      if ( pageNumber >= 3 && activePage === true && totalPageCreatedByAntd > 4 ) {
        setIsPageNum1Invisible(true);
      }

      if (pageNumber === 2 && activePage === true) {
        setIsPageNum1Invisible(false);
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block"; // #3
        }
        // Show #4 and #5 if switching from Total Pages < 8 to Total Pages >= 8 then back
        if (pageNum4 && pageNum5) {
          pageNum4.style.display = "inline-block";
          pageNum5.style.display = "inline-block";
        }
        if (pageNum4 && pageNum5 && totalPageCreatedByAntd >= 6) {
          pageNum4.style.display = "none";
          pageNum5.style.display = "none";
        }
        if (pageNum4 && totalPageCreatedByAntd === 5) {
          pageNum4.style.display = "none";
        }
      }

      // If Page #3 is active, display #5, #4 and #2
      if (pageNumber === 3 && activePage === true) {
        if (onePageBelowTheCurrent(pageNumber)) {
          onePageBelowTheCurrent(pageNumber).style.display = "inline-block";
        }
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        }
        if (totalPageCreatedByAntd !== 5 && twoPagesAboveTheCurrent(pageNumber)) {
          if (twoPagesAboveTheCurrent(pageNumber)) {
            twoPagesAboveTheCurrent(pageNumber).style.display = "none";
          }
        }
      }

      if (pageNumber >= 4 && activePage === true) {
        if (onePageAboveTheCurrent(pageNumber)) {
          onePageAboveTheCurrent(pageNumber).style.display = "inline-block";
        }

        twoPagesBelowTheCurrent(pageNumber).style.display = "none";
        onePageBelowTheCurrent(pageNumber).style.display = "inline-block";

        // Logic for Last Page is Active in Total Pages < 8
        if ((pageNumber === totalPageCreatedByAntd || pageNumber === totalPageCreatedByAntd - 1) && activePage === true) {
          twoPagesBelowTheCurrent(pageNumber).style.display = "inline-block";
          if (lastPageOfTable(totalPageCreatedByAntd - 4) && totalPageCreatedByAntd !== 5) {
            lastPageOfTable(totalPageCreatedByAntd - 4).style.display = "none";
          }
          if (lastPageOfTable(totalPageCreatedByAntd - 5) && totalPageCreatedByAntd !== 6 ) {
            lastPageOfTable(totalPageCreatedByAntd - 5).style.display = "none"; // #2 or #1
          }
          if (lastPageOfTable(totalPageCreatedByAntd - 3) && totalPageCreatedByAntd !== 4) {
            lastPageOfTable(totalPageCreatedByAntd - 3).style.display ="inline-block"; // #3 or #4
          }
        }
      }
    }

    if (type === "prev") {
      return (
        <IconButton
          onClick={() => setIsPageQuickJumperOpen(false)}
          type="primary"
        >
          <ArrowLeftOutlined />
        </IconButton>
      );
    }

    if (type === "next") {
      return (
        <IconButton
          onClick={() => setIsPageQuickJumperOpen(false)}
          type="primary"
        >
          <ArrowRightOutlined />
        </IconButton>
      );
    }

    if (type === "jump-next") {
      return (
        <Ellipsis style={{ backgroundColor: "red" }} type="primary">
          <ZindexedButton
            onClick={(e) => {
              e.stopPropagation();
              setIsPageQuickJumperOpen((prevBool) => !prevBool);
            }}
            type="link"
          >
            ...
          </ZindexedButton>
        </Ellipsis>
      );
    }

    return originalElement;
  };

  return (
    <>
      <StyledTable
        {...props}
        isPageNum1={isPageNum1Invisible}
        isPageQuickJumper={isPageQuickJumperOpen}
        columns={tableColumnHeader}
        dataSource={dataSource}
        pagination={{
          defaultCurrent: 1,
          hideOnSinglePage: hidePaginationOnSinglePage,
          defaultPageSize: defaultPageSize,
          current: currentPageNum,
          onChange: handleChangeTablePagination,
          pageSize: tableEntries,
          pageSizeOptions: pageSizeOptions,
          showQuickJumper: isPageQuickJumperOpen
            ? {
                goButton: (
                  <GoToButton
                    onClick={() => {
                      setCurrentPageNum(inputPageNum);
                      onPaginate(inputPageNum);
                      setIsPageQuickJumperOpen(false);
                    }}
                    type="primary"
                    shape="circle"
                    icon={<CheckOutlined />}
                    size="small"
                  />
                ),
              }
            : false,
          locale: {
            jump_to: (
              <>
                <StyledButton
                  disabled={Number(inputPageNum) === 1 ? true : false}
                  onClick={handleDecreasePageNum}
                  type="primary"
                  shape="circle"
                  icon={<MinusOutlined />}
                  size="small"
                />
                <input
                  name="Input Page Num"
                  type="text"
                  placeholder="#"
                  autoComplete="off"
                  maxLength={`${totalPageCreatedByAntd.toString().length}`}
                  value={Number(inputPageNum)}
                  onChange={handleChangePageNum}
                  onClick={e => e.target.select()}
                  onFocus={e => e.target.select()}
                />
                <StyledButton
                  disabled={Number(inputPageNum) === totalPageCreatedByAntd ? true : false}
                  onClick={handleIncreasePageNum}
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small"
                />
              </>
            ),
          },
          showSizeChanger: false,
          showTotal: (total, range) => {
            setCurrTablePageStartRange(range[0]);
            setCurrTablePageEndRange(range[1]);
            setCurrTablePageTotalEntries(total);
          },
          itemRender: customPrevNextAndPageNumPaginationBtn,
          total: isBackendPaginated && totalRecords ? totalRecords : undefined,
        }}
        locale={{
          // displays a component if there's no data
          emptyText: noDataAvailable,
        }}
      />
      {(dataSource?.length > 0 && !hideTotalSummaryOnSinglePage) && (
        <div className={`${!isMobileScreen && "flex justify-center"}`}>
          <TotalTextSummary>{`Showing ${currTablePageStartRange} to ${currTablePageEndRange} of ${currTablePageTotalEntries} entries`}</TotalTextSummary>
        </div>
      )}
    </>
  );
};

export default DataTable;

// CSS Styled Components ----------------------
const StyledTable = styled(Table)`
  // The Whole Table Container/Wrapper
  position: relative;
  width: 100%;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
  color: #2e2e2e;
  & .ant-table-thead .ant-table-cell {
    background-color: #eae9f0;
    white-space: pre-wrap;
  }
  // Prevent contents thead and tbody from wrapping when viewport shrinks
  & .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    white-space: nowrap;
  }
  // First Column of Table (Header & Body)
  & .ant-table-row .ant-table-cell:nth-child(1),
  .ant-table-thead .ant-table-cell:nth-child(1) {
    padding-left: 35px;
  }

  @media (max-width: 1024px) {
      & .ant-table-row .ant-table-cell:nth-child(1),
    .ant-table-thead .ant-table-cell:nth-child(1) {
      padding-left: 12px;
    }
  }

  // Last Column of Table (Header & Body)
  & .ant-table-row .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    padding-right: 35px;
    text-align: left;
  }

  // TABLE PAGINATION STYLES HERE
  .ant-table-pagination.ant-pagination {
    margin-top: 25px !important;
  }
  .ant-pagination-total-text {
    margin: 0;
  }

  .ant-pagination {
    position: relative;
    margin-right: 70px;
    @media (max-width: 720px) {
      margin-right: auto;
    }
    margin-left: auto;
    background-color: #eaeaea;
    width: fit-content;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    // Prev and Next Button
    & .ant-pagination-prev {
      position: absolute;
      left: -40px;
    }
    & .ant-pagination-next {
      position: absolute;
      right: -40px;
    }
    & .ant-pagination-jump-prev {
      display: none;
    }
    & .ant-pagination-item-1 {
      display: ${(props) => (props.isPageNum1 ? "none" : "inline-block")};
    }
    & .ant-pagination-jump-next {
      position: relative;
      z-index: 0;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      border: none;
    }
    & .ant-pagination-item-jump-next.improvised {
      background-color: #eaeaea;
      position: absolute;
      justify-content: center;
      text-align: center;
      width: 31.997px;
      right: 40px;
      margin-right: 16px;
      & .ant-btn.ant-btn-link.improvised {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 6px;
        color: #2e2e2e;
        &:hover {
          color: #1890ff;
        }
      }
    }

    // PAGE QUICK JUMPER STYLES
    .ant-pagination-options {
      background-color: #ffffff;
      box-shadow: rgb(136 136 136) 0px 2px 8px -4px;
      position: absolute;
      top: -65px; // -110px;
      height: 53px;
      min-width: 200px;
      border-radius: 5px;
      padding: 10px;
      // disables input field of quick jumper
      & .ant-pagination-options-quick-jumper {
        display: flex;
        align-items: center;
      }
      & .ant-pagination-options-quick-jumper input:nth-last-child(2) {
        display: none;
      }
    }

    // Last Page Number if page is greater than one page
    & .ant-pagination-item:nth-last-child(2) {
      margin-right: 15px;
    }
    & .ant-pagination-item:nth-last-child(3):not(.ant-pagination-item-2) {
      margin-right: ${(props) => (props.isPageQuickJumper ? "15px" : "15px")};
    }
    & .ant-pagination-item:nth-last-child(4) {
      margin-right: ${(props) => (props.isPageQuickJumper ? "15px" : "8px")};
    }
    // The Middle Element in a set of 4, is the 3rd element
    // Remove margin-right of page # when there's only 1 page
    & .ant-pagination-item:nth-child(3):nth-last-child(2) {
      margin-right: 0;
    }
    & .ant-pagination-item-link {
      border-radius: 50%;
      background-color: #0e4d94;
      color: white;
    }
    & .ant-pagination-item {
      border-radius: 50%;
      background-color: #eaeaea;
      border: none;
      font-family: Poppins !important;
    }
    & .ant-pagination-item-active {
      // Ensure all active page # is displayed
      display: inline-block !important;
    }
    & .ant-pagination-item-active,
    .ant-pagination-item-active a {
      border-radius: 50%;
      background-color: #0e4d94;
      color: white;
      border: none;
    }
    & .ant-pagination-disabled button {
      background-color: #0e4d94;
    }
  }
`;

const StyledButton= styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e4d94;
    color: white;
    border: none;
    &[disabled] {
      background-color: #0e4d94;
      color: white;
    }
    &[disabled]:hover, &:hover, &:focus{
      background-color: #0e4d94;
      color: white;
    }
`

const GoToButton = styled(Button)`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e4d94;
  color: white;
  border: none;
  &:hover, &:focus{
      background-color: #0e4d94;
      color: white;
    }
`;

const FlexBox = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataText = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 1.125rem; // 18px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
  color: #b6b6b6;
  margin-left: 10px;
  margin-top: 20px;
`;

const TotalTextSummary = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
  color: #b6b6b6;
  position: absolute;
  bottom: 40px;
  left: 30px;

  @media only screen and (max-width: 1024px) {
   position: relative;
   margin: -32px 0 0 -16px;
 }
   
  // Kurt Breakpoint (tablets, 719px and down)
  @media only screen and (max-width: 719px) {
      bottom: 40px !important;
      left: 30px !important;
      right: auto !important;
      /* font-size: 9px; */
  }
  @media only screen and (max-width: 897px){
      bottom: 0;
      left: auto;
      right: 75px;
  }

  /* // Small devices (tablets, 720px and up)
  @media only screen and (min-width: 720px) {font-size: 9px;}

  // Medium devices (tablets, 768px and up)
  @media only screen and (min-width: 768px) {font-size: 9px;}

  // Large devices (desktops, 992px and up)
  @media only screen and (min-width: 992px) {font-size: 9px;}

  // X-Large devices (large desktops, 1200px and up)
  @media only screen and (min-width: 1200px) {font-size: 9px;}

  // Macbook Pro 13-inch (laptops, 1280px and up)
  @media only screen and (min-width: 1280px) {font-size: 9.5px;}

  // Laptops 15.6inches (larger laptops, 1366px and up)
  @media only screen and (min-width: 1366px) {font-size: 11px;}

  // XX-Large devices (larger desktops, 1400px and up)
  @media only screen and (min-width: 1400px) {font-size: 12px;}

  // XXX-Large devices (larger desktops, 1400px and up)
  @media only screen and (min-width: 1536px) {font-size: 14px;} */
`;

const IconButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: #0e4d94;
  &:hover {
    background-color: #0e4d94;
  }
  .anticon {
    color: white;
  }
`;

const Ellipsis = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: #0e4d94;
  &:hover {
    background-color: #0e4d94;
  }
  &::after {
    display: none !important;
  }
  .anticon {
    color: white;
  }
`;

const ZindexedButton = styled(Button)`
  position: absolute;
  z-index: 2;
  width: 35px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #eaeaea;
  color: #2e2e2e;
  box-shadow: none;
  font-family: Poppins;
  &:hover {
    background-color: #eaeaea;
    color: #1890ff;
  }
  &:focus {
    background-color: #eaeaea;
    color: #2e2e2e;
  }
  &::after {
    display: none !important;
  }
  .anticon {
    color: white;
  }
`;
