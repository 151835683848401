import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react'
import UI from '../../shared/Components/UI/js';
import TouchableOpacity, { mem, ScrollView, Text, View } from '../../shared/custom-react-native';
import APIConstants from '../../shared/modules/APIConstants';
import Header from '../advocacy-doctor-homepage/Header';
import { Checkbox } from '../_components/InputForm';
import Loading from '../_components/Loading';
import Modal, { CustomSubmittedModal, Failed, 
  // Success
 } from '../_components/Modal';
import FirstForm from './FirstForm';
import Footer from './Footer1';
// import MOA from './MOA';
import { PRCValidator } from './PRCValidator';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';

//Google Analytics
import ReactGA from 'react-ga';
import { smartech } from '../../utils/smartech';


const context = "advocacy-doctor-register"
const Constants = new APIConstants();
const minOffset = 0;
const maxOffset = 60; 



export default class AdvocacyDoctorRegister extends Component {

  query = null
  constructor(props) {
    super(props);

    const thisYear = (new Date()).getFullYear();

    UI.initiateView(this, {
      //Initial State Here
      context: context,
      // First Form State
      first_name:'',
      last_name:'',
      email:'',
      contact_no:'',
      specialty:'',
      hospital:'',
      hmo_affiliation:'',
      prc_number:'',
      languange: '',
      ip_address: '',

      dob:'',
      gender:'male',
      qualification_date:'',
      license_start_date:'',
      license_end_date:'',
      show_loading: false,
      loading_title: "",
      loading_text: "",
      advocacy:null,
      profile_picture_path:"",
      signature: "",
      years_of_practice:"",
      thisYear: thisYear,

      file_signature_path: { url: null, extension: null, filename: "" },
      file_prc_license_path: { url: null, extension: null, filename: "" },
      file_user_profile_path: { url: null, extension: null, filename: "" },
      downloaded_mpc: { url: null, extension: "" },

      specializations: [],
      hospitals:[],
      languanges:[],
      users:[],
      allowedHospital:[],
      allowedLanguanges:[],
      hmo_affiliations: [],

      mpc: "",
      mpc_file: null,

      image: "",
      image_file: null,
      isHideForm:false,

      show_modal: false,
      modal_content: "",
      modal_opaque: false,
      is_agree: false,
      is_check_moa: false,
    });
  } 
  
  componentWillMount = async () => {
    this.loadAdvocacyDetails();
    this.load_specialization();
    this.alreadyMwellDoctor();
    this.load_hospitals()
    this.load_languange()
    this.load_affiliations()
    this.getUserIpAddress()
  };

  loadAdvocacyDetails = () => {
    const queryAdvocacyId = new URLSearchParams(this.props.location.search).get("advocacy");
    const options = []

    for (let i = minOffset; i <= maxOffset; i++) {
      const year = this.state.thisYear - i;
      options.push(year);
    }
    this.show_loading()
    UI.webRequest({
     method: "get",
     url: "admin/advocacies/" + queryAdvocacyId,
     onFail: async (err) => {},
     onSuccess: async (response) => {
       const data = UI.extract_data(response);
       this.setState({ advocacy: data.data.advocacy, years: options })
     },
     onFinish: () => {
       this.hide_loading()
     },
   });
  }

  load_languange = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/doctor-hospitals",
      params: {},
      onFail: (err) => {
        console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          languanges: data.data,
        });
      },
      onFinish: async () => {
        // this.load_doctor();
        this.hide_loading();
      },
    });
  };

  load_specialization = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/advocacy-specializations",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        const new_data = data.sort(UI.dynamicSort("label"));
        if(this.query.includes("step=1")) {
          this.setState(
            {
              specializations: new_data,
            },
            () => {
              this[`${"reload_multiple_option_"}${"specialty"}`]();
            }
          );
        }
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  load_hospitals = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/doctor-hospitals",
      params: {},
      onFail: (err) => {
        console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          hospitals: data.data,
        });
      },
      onFinish: async () => {
        // this.load_doctor();
        this.hide_loading();
      },
    });
  };

  load_affiliations = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/hmo-affiliations",
      params: {},
      onFail: (err) => {
        console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          hmo_affiliations: data.data,
        });
      },
      onFinish: async () => {
        // this.load_doctor();
        this.hide_loading();
      },
    });
  };

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  failed_modal = (message) => {
    console.log(message)
    this.show_modal(
      <Failed description={message} onDismiss={this.hide_modal} />
    );
  };

  componentDidMount = () => {
    const _this = this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;

    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
  };

  alreadyMwellDoctor = () => {
    this.show_loading();

    const doctor_data = mem.get("onboard_data");
    const doctor = JSON.parse(doctor_data);
    const queryAdvocacyId = new URLSearchParams(this.props.location.search).get("advocacy");
    const queryAdvocacyTitle = new URLSearchParams(this.props.location.search).get("advocacy_name");


    if(doctor) {

      UI.webRequest({
        method: "post",
        url: "doctors_onboarding/doctors/join-advocacy",
        params: {
        stage:1,
        practitionerId: doctor.doctor.id,
        advocacyID: queryAdvocacyId
      },
        onFail: async (err) => {
          this.setState({isHideForm: true})
          const data = err.data;
          let error_message = data.message;
          if (!error_message) {
            error_message = data.m;
          }
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                window.location.href = `${Constants.MWELL_WEBSITE_URL}/advocacy`
              }}
            />
          );
        },
        onSuccess: async (response) => {
          UI.extract_data(response);
          this.setState({isHideForm: true})
          this.show_modal(
            <CustomSubmittedModal 
              headerTitle="Advocacy Registration Successful!"
              descriptionOne="Thankyou for signup for"
              descriptionTwo="You can now set your advocacy schedule in the doctor's portal."
              title={queryAdvocacyTitle}
              onOk={{
                text:'Return to Doctor`s Portal',
                method: () => {
                  this.hide_modal();
                  UI.goTo("/onboard/dashboard")
                  // window.location.href = "https://www.mwell.com.ph/advocacy/"
                },
              }}
            />
          );
          // this.hide_loading();
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
    }
  }

  
  auth_upload = async (state_name, file, callback) => {
    UI.upload_image({
      url: "doctors_onboarding/doctors/public-upload",
      file: file,
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Upload Failed. Application Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        this.setState(
          {
            [state_name]: path,
          },
          () => {
            if (callback) {
              callback(path);
            }
            this.process_file(state_name, file, path);
          }
        );
      },
    });
  };

  process_file = async (state_name, file, path) => {
    const filename = UI.container_filename(path);
    const extension = await UI.get_file_extension(path);
    const url = URL.createObjectURL(file);
    const obj = {
      url: url,
      extension: extension,
      filename: filename,
    };

    this.setState({
      ["file_" + state_name]: obj,
    });
  };

  submitRegistrationStageOne = async () => {
    UI.clear_errors();
    let {
      first_name,
      last_name,
      email,
      contact_no,
      specialty,
      prc_number,
    } = this.state;

    const isSpecialCharsFirstname = UI.is_check_special_characters(first_name)
    const isSpecialCharsLastname = UI.is_check_special_characters(last_name)

    if (!first_name) {
      await UI.error_form("first_name", "First Name is required.");
    }
    if (isSpecialCharsFirstname) {
      await UI.error_form("first_name", "Characters is not allowed.");
    }

    if (!last_name) {
      await UI.error_form("last_name", "Last Name is required.");
    }

    if (isSpecialCharsLastname) {
      await UI.error_form("last_name", "Characters is not allowed.");
    }

    if (!email) {
      await UI.error_form("email", "Email is required.");
    }

    if (email) {
      if (!UI.validate_email(email)) {
        await UI.error_form("email", "Please enter a valid email.");
      }
    }

    if (!contact_no) {
      await UI.error_form("contact_no", "Contact Number is required.");
    }
    if (contact_no.length !== 10) {
      await UI.error_form(
        "contact_no",
        "Format should be 9xxxxxxxxx is required."
      );
    }
    
    if (!prc_number) {
      await UI.error_form("prc_number", "PRC Number is required.");
    }
    if (prc_number.length !== 7) {
      await UI.error_form("prc_number", "PRC Number should be 7 digits.");
    }

    if (specialty.length === 0) {
      await UI.error_form("specialty", "Specialty is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    if(this.query.includes("step=1")) {
      this.show_loading();
      console.log(this.props.location)
      const queryAdvocacyId = new URLSearchParams(this.props.location.search).get("advocacy");
   
      UI.webRequest({
        method: "post",
        url: "doctors_onboarding/doctors/join-advocacy",
        params: {
        stage: 1,
        firstName: first_name,
        lastName: last_name,
        emailAddress: email,
        mobileNo: contact_no,
        fieldSpecialties: specialty,
        prcLicenseNumber: prc_number,
        role: "provider",
        advocacyID: queryAdvocacyId
      },
        onFail: async (err) => {
          const data = err.data;
          let error_message = data.message;
          if(data.m.includes("existing profile")) {
            error_message = "You have an existing profile with mWell. Please login your account to continue signing up for National mWell Day.";
            this.show_modal(
              <Failed
                title={"Failed"}
                description={error_message}
                onDismiss={() => {
                  this.hide_modal();
                }}
                onOk={{
                  text:'Login',
                  method: () => {
                    this.hide_modal();
                    // window.location.href = `https://mwelltelemedapp.b2clogin.com/mwelltelemedapp.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/authorize?client_id=bc86f04f-18fd-411e-8e45-f2e479a9d8cb&redirect_uri=${Constants.WEB_HOST_NAME_URL}&scope=bc86f04f-18fd-411e-8e45-f2e479a9d8cb%20offline_access&response_type=code`
                    window.location.href = `${Constants.WEB_HOST_NAME_URL}/onboard/login`
                  },
                }}
              />
            );
            return
          }

          if(data.m.includes("pending application")) {
            error_message = "There is a pending application with your prc license number or email address. Please wait us to get in touch with you and discuss the next steps of your registration";
            this.show_modal(
            <Failed
              title={"Error"}
              onDismissText="Return to Dashboard"
              description={error_message}
              onDismiss={() => {
                window.location.href =`${Constants.MWELL_WEBSITE_URL}/advocacy`;
                this.hide_modal();
              }}
            />)
            return 
          }

          if (!error_message) {
            error_message = data.m;
          }

          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          UI.extract_data(response);
          this.show_modal(
            <CustomSubmittedModal 
              headerTitle="Advocacy Registration Submitted!"
              descriptionOne="Thank you for signing up for"
              descriptionTwo="The event details will be sent to your email: "
              descriptionThree={`If you do not see our email, kindly check your junk mail or spam folder.`}
              title={this.state.advocacy.title} 
              email={this.state.email} 
              onOk={{
                text:'Return to Advocacy',
                method: () => {
                  this.hide_modal();
                  window.location.href = "https://www.mwell.com.ph/advocacy/"
                },
              }}
            />
          );
          this.hide_loading();
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
   
    }
  }

  submitRegistrationStageTwo = async () => {
    let {
      license_end_date_date,
      license_start_date_date,
      prc_license_path,
      prc_number,
    } = this.state;

    const queryPractitionerID = new URLSearchParams(this.props.location.search).get("practitionerID");
    
    if (!prc_license_path) {
      return this.failed_modal("Please upload your OCR (.jpeg, or .png only)");
    }

    if (!prc_number) {
      return this.show_modal(
        <Failed 
          description={"We can't view prc number you uploaded. Here are some tips to help you take a clearer picture."} 
          descriptionOne={<span>
            {UI.box(10)}
            1. Use a dark background. <br></br>
            2. Take the photo in a well-lit area. <br></br>
            3. Make sure your lens are clean before taking the photo. <br></br>
            4. Upload a .jpeg or .png file. <br></br>
          </span>} 
          onDismiss={this.hide_modal} 
        />
      );
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    UI.webRequest({
      method: "post",
      url: "doctors_onboarding/doctors/join-advocacy",
      params: {
      stage: 2,
      prcLicenseStartDate: license_start_date_date,
      prcLicenseEndDate: license_end_date_date,
      prcLicensePhoto: prc_license_path,
      prcLicenseNumber: prc_number,
      practitionerId: queryPractitionerID
    },
      onFail: async (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        UI.extract_data(response);
        this.show_modal(
          <CustomSubmittedModal 
            headerTitle="Advocacy Registration Submitted!"
            // centerTitle="Thank you for"
            descriptionOne="Thank you for accepting our call. Once we receive your PRC ID, our Operations team will validate your details and send you the relevant advocacy materials, as well as walk you through the platform. "
            onOk={{
              text:'Return to Advocacy',
              method: () => {
                this.hide_modal();
                window.location.href = "https://www.mwell.com.ph/advocacy/"
              },
            }}
          />
        );
        this.hide_loading();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  submitRegistrationStageThree = async () => {    
    let {
      signature_path,
      profile_path,
      hospital,
      languange,
      mpc,
      is_check_moa,
      hmo_affiliation,
      years_of_practice,
      ip_address
    } = this.state;

    const yearsOfExperience = parseInt(years_of_practice)
    const currentYear = parseInt(moment(new Date()).format('YYYY'))
    const computeYearOfExperience =  currentYear - yearsOfExperience

    const queryPractitionerID = new URLSearchParams(this.props.location.search).get("practitionerID");

    if (!profile_path) {
      return this.failed_modal("Please upload your profile picture (.jpeg, or .png only)");
    }

    if (!signature_path) {
      return this.failed_modal("Please upload your signature (.jpeg, or .png only). Use blank ink on white background");
    }

    // if (!mpc) {
    //   return this.failed_modal("MPC upload is required.");
    // }

    if (hospital.length === 0) {
      await UI.error_form("hospital", "Hospital is required.");
    }

    if (languange.length === 0) {
      console.log('asd')
      await UI.error_form("languange", "Languange is required.");
    }

    if (!is_check_moa) {
      return this.failed_modal("Please view and check the Memorandum of Agreement");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    UI.webRequest({
      method: "post",
      url: "doctors_onboarding/doctors/join-advocacy",
      params: {
      stage: 3,
      hospital: hospital,
      signature: signature_path,
      picture: profile_path,
      mpc: mpc,
      languange: languange,
      practitionerId: queryPractitionerID,
      yearsExperience: computeYearOfExperience,
      moa: is_check_moa,
      hmoAffiliations: hmo_affiliation,
      ipAddress:ip_address
    },
      onFail: async (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        UI.extract_data(response);
        this.show_modal(
          <CustomSubmittedModal 
            headerTitle="Successfully Registered"
            descriptionOne="Thank you for giving us your professional details. This information will help us create your doctor profile on the app."
            onOk={{
              text:'Return to Advocacy',
              method: () => {
                this.hide_modal();
                window.location.href = "https://www.mwell.com.ph/advocacy/"
              },
            }}
          />
        );
        this.hide_loading();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  getUserIpAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data.IPv4);
    this.setState({ip_address: res.data.IPv4})
  }

  process_file = async (state_name, file, path) => {
    const filename = UI.container_filename(path);
    const extension = await UI.get_file_extension(path);
    const url = URL.createObjectURL(file);
    const obj = {
      url: url,
      extension: extension,
      filename: filename,
    };

    this.setState({
      ["file_" + state_name]: obj,
    });
  };

  render() {
    const { isMobile, width } = this.state
    this.query = this.props.location.search

    const image = `${Constants.STORAGE_BASE_URL}?path=${this.state.advocacy?.imagePath}` ?? UI.SAMPLE_PRODUCT
    const startDate = moment(this.state.advocacy?.startDate).format('MMMM D YYYY')
    const endDate = moment(this.state.advocacy?.endDate).format('MMMM D YYYY')
    const update_agree = () => {
      this.setState({
        is_agree: !this.state.is_agree,
      });
    };

    return (
      <ScrollView>

        <Loading
          visible={this.state.show_loading}
          title={this.state.loading_title}
          text={this.state.loading_text}
          modal_opaque={this.state.modal_opaque}
        />

        <Modal _this={this} visible={this.state.show_modal} />
        <PRCValidator _this={this} />

        <Header _this={this}/>
        <div 
          // style={{backgroundImage: `url(${UI.DOCTOR_ADVOCACY_REGISTRATION_BANNER})`, opacity:1, backgroundRepeat:'no-repeat', backgroundSize:'100%'}}
        >
          { 
            isMobile ? <img src={UI.DOCTOR_ADVOCACY_REGISTRATION_BANNER_MOBILE} alt='' style={{width:"100%", height:'auto'}}/> 
            : <img src={UI.DOCTOR_ADVOCACY_REGISTRATION_BANNER} alt='' style={{width:"100%", height:'auto'}}/>
          }
        
          {/* <div className={`relative ${isMobile ? 'px-8 py-10' : 'px-16 py-20 '}`} >
            <h1 className='m-0 flex justify-center text-white font-semibold text-2xl'>VIEW MWELL ADVOCACY</h1>
            {UI.box(77)}
          </div> */}
        </div>

        <div className={`${isMobile ? 'px-8 py-10' : 'px-16 py-5 '}`} style={{ fontFamily: 'Poppins,sans-serif'}}>
          <div className={`bg-white rounded-md  ${isMobile ? 'flex-col py-3': 'py-0 px-8'} justify-around `} style={{boxShadow:'0px 3px 6px #00000029', width:'100%'}}>
            <div className={`flex items-center gap-5 ${isMobile ? 'flex-col py-5 px-5' : 'py-6 px-0'}`}
              style={{alignItems: width < 900 && "center", flexDirection: width < 900 &&'column', textAlign: width < 900 &&'center'}}
            >
              <h1 className={`m-0`} style={{color:"#0056AE", fontSize: isMobile ? 15 : 19}}>Selected<span className='ml-2'>Advocacy</span>  </h1>
              <div style={{ borderRadius:'8px', height: width < 900 ? 120 : "auto", width: width < 900 ? 'auto' : 350}}>
                <img src={image} alt="" style={{height:'100%', width:"100%", borderRadius:'8px'}}/>
              </div> 
              <h1 className={`m-0 ${isMobile && "text-center"} `} style={{color:"#0056AE", fontSize: isMobile ? 15 : 19,}}>{this.state.advocacy?.title} </h1>

              <span className={`text-gray-600 m-0 font-semibold inline-flex gap-1 `} style={{width: isMobile ? '100%' : '50%', flexDirection: width < 900 && 'column', justifyContent:'flex-end', gap:'5px', alignItems:'center', fontSize:15}}>
                <img src={UI.AVAILABILITY} style={{ height: 25, alignItems: width < 1500 ? "items-start" : "items-center", marginTop: width < 1309 ? -12 : 0}} alt="" width={25}/>
                <span style={{fontSize: width < 900 && 13, marginTop: width < 900 && 5}}>From: {startDate}-{endDate}</span>
              </span>
            </div>
            {/* {!isMobile && UI.box(20)}
            <div className='flex gap-5 items-center justify-center'>
              <span className="text-gray-600 m-0 font-semibold" style={{textAlign:'center', display:'flex', justifyContent:'center', gap:'5px', alignItems:'center', fontSize:15}}>
                <span className={`inline-flex  gap-1 ${isMobile && "flex-col p-5"} items-center`} style={{flexDirection: width < 900 && 'column'}}>
                  <img src={UI.AVAILABILITY} style={{ height: 25, alignItems: width < 1500 ? "items-start" : "items-center", marginTop: width < 1770 ? -12 : 0}} alt="" width={25}/>
                  <span style={{fontSize: width < 900 && 13, marginTop: width < 900 && 5}}>From: {startDate}-{endDate}</span>
                </span>
              </span>
            </div> */}
          </div>
        </div>

        {/* {UI.box(200)} */}

        <div className='px-16 pb-8' style={{ fontFamily: 'Poppins,sans-serif'}}>
          <span className="text-2xl" style={{color:"#0056AE"}}>Advocacy Registration</span>
          {UI.box(15)}

          <View>
            { this.query.includes("step=1") ? !this.state.isHideForm && <>

            <FirstForm _this={this} />
            
            {UI.box(20)}

            <Text style={{ marginTop: -3, fontSize: 16, color:'#5E6D7D'  }}>
              - After submission, our representative will get in touch with you to discuss the details of your registration.
            </Text>
            {UI.box(10)}
            <View style={{ flexDirection: "row", alignItems:'center' }}>
              <View style={{ alignSelf: "flex-start", alignItems:'center', display:'flex' }}>
                <Checkbox
                  is_checked={this.state.is_agree}
                  onClick={update_agree}
                />
              </View>
              
              <Text style={{ marginTop: -3, fontSize: 16 }}>
                <span onClick={update_agree}>By continuing, I agree to the</span>{" "}
                <a href={"https://www.mwell.com.ph/support?title=terms-and-conditions&subtitle=advocacy"} style={{color:'#40a9ff'}} target="_blank" rel="noopener noreferrer">
                  mWell Advocacy Terms and 
                </a>{" "}
                <a href={"https://www.mwell.com.ph/support?title=terms-and-conditions&subtitle=advocacy"} style={{color:'#40a9ff'}} target="_blank" rel="noopener noreferrer">
                Conditions
                </a>
                .
              </Text>
            </View>
            
            {UI.box(20)}
            
            {
              !this.state.isHideForm &&
              <div className='flex gap-5'>
                <TouchableOpacity onClick={() => {
                    ReactGA.event({
                      action:'advocacy_nmd_doctor_registration', 
                      category:'Advocacy NMD Page',
                    });

                    smartech("dispatch", "advocacy_nmd_doctor_registration", {
                      page_url: window.location.href,
                      page_title: document.title,
                      category:'Advocacy NMD Page'
                    });

                  if(this.state.is_agree) {
                    this.submitRegistrationStageOne()
                  } 
                  // else {
                  //   if(this.query.includes("step=2") || this.query.includes("step=3")) {
                  //     this.submitRegistrationStageOne()
                  //   }
                  // }
                }}>
                  <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" 
                    style={{backgroundColor: this.state.is_agree || this.query.includes("step=2") || this.query.includes("step=3") ? '#0056AE' : "rgb(99, 110, 114)", fontSize:'12px'}}
                  >
                    REGISTER TO ADVOCACY
                  </button>
                </TouchableOpacity>
                <TouchableOpacity onClick={() => {

                 
                  // this.returnToAdvocayDetails(this.state.advocacy?._id)
                  window.location.href = "https://www.mwell.com.ph/advocacy/"
                }}>
                  <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" style={{backgroundColor:'#E00000', fontSize:'12px'}}>
                    CANCEL
                  </button>
                </TouchableOpacity>
              </div>
            }
            </> : ""}
            { this.query.includes("step=2") ? 
              <>
                <SecondForm _this={this} /> 
                
                {UI.box(10)}

                <Text style={{ marginTop: -3, fontSize: 16, fontWeight:'bold', color:'#5E6D7D'  }}>
                  - After submission, an mWell representative will send you an email with a link to complete your doctor profile for the mWell PH app.​
                </Text>

                {UI.box(20)}

                <div className='flex gap-5'>
                  <TouchableOpacity onClick={() => {
                    this.submitRegistrationStageTwo()
                    console.log('first')
                  }}>
                    <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" 
                      style={{backgroundColor: this.state.is_agree || this.query.includes("step=2") || this.query.includes("step=3") ? '#0056AE' : "rgb(99, 110, 114)", fontSize:'12px'}}
                    >
                      SUBMIT
                    </button>
                  </TouchableOpacity>
                </div>

              </>: ""}
            { this.query.includes("step=3") ? 
              <>
                <ThirdForm _this={this} />


                <Text style={{ marginTop: -3, fontSize: 16, fontWeight:'bold', color:'#5E6D7D'  }}>
                  - Thank you for completing your registration. This information will be available on the mWell PH app.​
                </Text>

                {UI.box(20)}

                <div className='flex gap-5'>
                  <TouchableOpacity onClick={() => {
                    this.submitRegistrationStageThree()
                    console.log('first')
                  }}>
                    <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" 
                      style={{backgroundColor: this.state.is_agree || this.query.includes("step=2") || this.query.includes("step=3") ? '#0056AE' : "rgb(99, 110, 114)", fontSize:'12px'}}
                    >
                      SUBMIT
                    </button>
                  </TouchableOpacity>
                </div>
              </>
               : ""
            }

            
          </View>

        </div>

        <Footer _this={this}/>
      </ScrollView>
    )
  }
}
