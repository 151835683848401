import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography, DatePicker } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceDoctorsApi from "../../api_services/doctors";
import ServiceAdminsApi from "../../api_services/admins";

const DoctorFileRepositoryTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [sortByStartEndDate, setSortByStartEndDate] = useState([]);

  const getDoctorFiles = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (sortByStartEndDate.length > 1) {
        params.startDate = sortByStartEndDate[0];
        params.endDate = sortByStartEndDate[1];
      }

      if (searchQuery) {
        params.fullname = searchQuery;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      ServiceDoctorsApi.getDoctorFiles(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          setTotalRecords(res.count);
          setTableData(data.map((obj) => ({ ...obj, key: obj._id })));
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, sortByStartEndDate, searchQuery]);

  useEffect(() => {
    getDoctorFiles();
  }, [getDoctorFiles]);

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const handleDownloadDoctorFiles = async (data) => {
    try {
      setIsLoading(true);
      await ServiceAdminsApi.downloadDoctorLegalFiles(data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  }

  const tableColumnHeader = [
    {
      title: "Doctor Name",
      dataIndex: "name",
      key: "name",
      render: (name) => UI.DataHelper.carespan.get_name(name) ?? " ",
      // render: (key, item) =>
      //   UI.get_fullname(item.firstName, item.middleName, item.lastName) ?? " ",
    },
    {
      title: "Date Generated",
      dataIndex: "createdAt",
      key: "date_added",
      render: (data) => {
        return moment(data).format("MM/DD/YYYY");
      },
    },
    {
      title: "Moa",
      dataIndex: "moaPathURL",
      key: "file_moa",
      render: (data) => {
        if (data) {
          return (
            <>
              <LinkButton
                type="link"
                style={{ color: UI.colors.primary }}
                onClick={() => {
                  handleDownloadDoctorFiles(data);
                }}
              >
                Click to view MOA
              </LinkButton>
            </>
          );
        } else {
          return "No File";
        }
      },
    },
    {
      title: "Marketing Consent",
      dataIndex: "marketingConsentPathURL",
      key: "file_consent",
      render: (data) => {
        if (data) {
          return (
            <>
              <LinkButton
                type="link"
                onClick={() => {
                  handleDownloadDoctorFiles(data);
                }}
              >
                Click to view Marketing Consent
              </LinkButton>
            </>
          );
        } else {
          return "No File";
        }
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   fixed: "right",
    //   width: 100,
    // },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  const handleFilterByShiftDateRange = (dates, dateStrings) => {
    console.log(dates);
    console.log(dateStrings);
    setSortByStartEndDate(dateStrings);
  };

  // const handleClearViaSelectField = (fieldName) => {
  //   console.log("Handle CLEAR", fieldName);
  //   if (fieldName === "Date Filter by") {
  //     setSearchResults([]);
  //   }
  // };

  return (
    <>
      <FilterBox>
        <Title level={5}>Doctor File Repository</Title>
        <Toolbar gutter={14}>
          {/* <ToolItem span={8}>
            <StyledFieldLabel>File Type</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setFilterByGender(value)}
              onClear={() => handleClearViaSelectField("Gender")}
            >
              <Select.Option title="File Type" value="moa">
                MOA
              </Select.Option>
              <Select.Option title="File Type" value="marketing_consent">
                Marketing Consent
              </Select.Option>
            </StyledSelect>
          </ToolItem> */}
          <ToolItem span={8}>
            <StyledFieldLabel>Date Range</StyledFieldLabel>
            <StyledRangePicker
              separator={<span className="mr-6">-</span>}
              size="large"
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={handleFilterByShiftDateRange}
            />
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="30">30 Entries</Select.Option>
            <Select.Option value="100">100 Entries</Select.Option>
            <Select.Option value={totalRecords}>All Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getDoctorFiles}
            style={{ width: 200 }}
          />
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default DoctorFileRepositoryTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

// const StyledButton = styled(Button)`
//   height: 50px;
//   border-radius: 12px;
//   border: none;
//   background-color: #0e4d94;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 0.9375rem; // 15px
//   line-height: 1.4375rem; // 23px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover {
//     background-color: #43c8ff;
//   }
// `;

const LinkButton = styled(Button)`
  flex: 1;
  padding: 0;
  height: 100%;
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

// const FlexRow = styled(Row)`
//   flex-direction: row;
//   position: absolute;
//   left: 9px;
//   bottom: 2px;
//   z-index: 1;
//   align-items: center;
// `;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    display: block !important;
  }
`;
