import { Button, Row, Select, Typography } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import DataTable from "../../../../ComponentsFC/DataTable";
import UI from "../../../../shared/Components/UI/js";
import TouchableOpacity, {
  Text,
} from "../../../../shared/custom-react-native";
import APIConstants from "../../../../shared/modules/APIConstants";
const Constants = new APIConstants();

const DoctorAppointmentUploadFilesTable = ({_this}) => {
  const location = useLocation(); // Get the current location
  const query = new URLSearchParams(location.search); // Parse the query string
  const appointmentId = query.get('appointment_id');
  const [isLoading, setIsLoading] = useState(false);
  const [doctorFiles, setDoctorFiles] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
    const [currentPageNum, setCurrentPageNum] = useState(1);
  const [tableEntries] = useState(5)
  const fileInputRef = useRef(null);


  useEffect(() => {
    loadData(appointmentId);
  }, [appointmentId]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const loadData = async(appointmentId) => {
    setIsLoading(true);
    UI.webRequest({
      method: "get",
      url: `doctors_onboarding/appointments/${appointmentId}`,
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        setDoctorFiles(data.appointment.doctorUploadedFiles);
      },
      onFail: async (err) => {
        console.error(err);
      },
      onFinish: () => {
        setIsLoading(false);
      },
    })
  }

  const sendEmail = async(payload) => {
    setIsLoading(true);
    // console.log(payload);
    UI.webRequest({
      method: 'post',
      url: `doctors_onboarding/appointments/send-uploaded-files/${appointmentId}`,
      params: payload,
      onSuccess: async (response) => {
        loadData(appointmentId);
      },
      onFail: async (err) => {
        console.error(err);
      },
      onFinish: () => {
        setIsLoading(false);
      },
    })
  }

  const handleSendEmail = (item) => {
    // Logic for sending email goes here
    // console.log(`Sending email for ${item.filename}`);
    const filePath = item.filePath;
    const payload = {
      filePath: filePath
    }
    sendEmail(payload);
  };

  const handleSendAllFiles = () => {
    // Logic for sending email goes here
    // console.log(`Send all`);
    const payload = {
      sendAll: true
    }
    sendEmail(payload)
  };

  const uploadFiles = async(files) => {
    if(!files.length) return;
    setIsLoading(true);
    const formData = new FormData();
    Array.from(files).forEach(file=> {
      formData.append('multi-files',file);
    });
    // console.log(formData);
    await UI.webRequest({
      method: 'post',
      url: `doctors_onboarding/appointments/doctor-upload/${appointmentId}`,
      params: formData,
      onSuccess: async (response) => {
        loadData(appointmentId);
      },
      onFail: async (err) => {
        console.error(err);
      },
      onFinish: () => {
        setIsLoading(false);
      },
    })
  }

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setSelectedFiles(Array.from(files));
      // console.log(files)
      uploadFiles(files);
      setErrorMessage('');
    } else {
      setErrorMessage('No files selected.'); 
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const downloadFile = (path, fileName) => {
    const url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=patient-files`;
    // console.log(url);
    fetch(url, {
      method: "GET",
      headers: {}
    })
    .then(response => {
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
  }

  const tableColumnHeader = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt, item) => {
        return (
          <div className="flex gap-3" style={{ color: UI.colors.header }}>
            {doctorFiles
              ? UI.momentPHTimezone(
                  doctorFiles.uploadedOn
                ).format("MM/DD/YYYY")
              : "--"}
          </div>
        );
      },
      width: 100,
    },
    {
      title: "Filename",
      dataIndex: "description",
      key: "description",
      render: (description, item) => {
        return (
          <div className="flex gap-3">
            {item?.filename ?? "--"}
          </div>
        );
      },
      width: 200,
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (file, item) => {
        const baseContent = `${Constants.STORAGE_BASE_URL}?path=${item.filePath}&container=users`;

        return (
          <TouchableOpacity
            onClick={() => {
                downloadFile(item.filePath, item.filename);
            }}
            style={{
              textDecoration: "underline",
              textDecorationColor: UI.colors.primary,
            }}
          >
            <Text style={{ color: UI.colors.primary }}>
              {item.filename ?? "--"}
            </Text>
          </TouchableOpacity>
        );
      },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (file, item) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => handleSendEmail(item)}
              style={{
                backgroundColor: UI.colors.primary,
                color: "white",
                border: "none",
                padding: "8px 16px",
                borderRadius: "4px",
              }}
            >
              Send Via Email
            </Button>
            <Text
              style={{
                textDecoration: "underline",
                color: item.isSent ? "green" : "red", // Change color based on isSent
                display: "block", // Ensure it displays below the button
                marginTop: "4px",
              }}
            >
              {item.isSent ? `Sent on ${UI.momentPHTimezone(
                  item.sentOn
                ).format("MM/DD/YYYY")}` : "Not Sent"}
            </Text>
          </div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className="w-full">
      <TableBox>
        <Toolbar>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              color: "#0E4D94",
              paddingBlock: "8px"
            }}
          >
            Uploaded Files
          </Text>

          {/* Buttons for sending files and uploading */}
          <div className="flex items-center">
            <button
              onClick={handleUploadClick}
              className="p-3 font-semibold flex rounded-md text-white"
              style={{ background: "#0E4D94"}}
            >
              Upload files
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{display: 'none'}}
              multiple
              accept=".pdf, .doc, .docx"
              onChange={handleFileChange}  />
            {UI.box(10)}
            <button
              onClick={() => {
                handleSendAllFiles();
              }}
              className="p-3 font-semibold flex rounded-md text-white"
              style={{ background: UI.colors.primary }}
            >
              Send all files
            </button>
          </div>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={doctorFiles}
          // defaultPageSize={5}
          // pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={false}
          hideTotalSummaryOnSinglePage={true}
          hidePaginationOnSinglePage={true}
          // totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </div>
  );
};

export default DoctorAppointmentUploadFilesTable;

const TableBox = styled(Row)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 20px 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  /* min-width: 50%; */
  /* height: 100%; */
  flex: 1;
  margin-bottom: 25px;
`;

const Toolbar = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;
